/* Store.ts */

import { configureStore } from '@reduxjs/toolkit'
import GlobalReducer from './reducers/GlobalReducer'
import { useDispatch, useSelector } from 'react-redux'
import ConfigReducer from './reducers/ConfigReducer'
import AccessTokenReducer from './reducers/TokenReducer'

export const store = configureStore({
  reducer: {
    globalSlice: GlobalReducer,
    configSlice: ConfigReducer,
    tokenSlice: AccessTokenReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()