import moment from "moment";

// src/DateTimeHelper.ts
class DateTimeHelper {
    private locale: string;

    constructor() {
        this.locale = navigator.language || 'en-GB';
    }

    formatDate(date: Date | string, options: Intl.DateTimeFormatOptions = {}): string {
        const defaultOptions: Intl.DateTimeFormatOptions = {
            weekday: 'short', day: 'numeric', month: 'short', year: 'numeric'
        };
        return new Date(date).toLocaleDateString(this.locale, { ...defaultOptions, ...options });
    }

    formatTime(date: Date | string, options: Intl.DateTimeFormatOptions = {}): string {
        const defaultOptions: Intl.DateTimeFormatOptions = {
            hour: '2-digit', minute: '2-digit',
        };
        return new Date(date).toLocaleTimeString(this.locale, { ...defaultOptions, ...options });
    }

    formatDateTime(date: Date | string, dateOptions: Intl.DateTimeFormatOptions = {}, timeOptions: Intl.DateTimeFormatOptions = {}): string {
        return `${this.formatDate(date, dateOptions)} ${this.formatTime(date, timeOptions)}`;
    }
}

export default DateTimeHelper;

export const formatDate = (date: Date, format: string): string => {
    return moment(date).format(format);
};

export const getFutureDateByAddingDays = (date: Date, days: number): Date => {
    const startingMoment = moment(date);
    const futureDate = startingMoment.add(days, 'days');
    return futureDate.toDate()
}

export const getFutureDateByAddingWeeks = (date: Date, weeks: number): Date => {
    const startingMoment = moment(date);
    const futureDate = startingMoment.add(weeks, 'weeks');
    return futureDate.toDate()
}

export const getFutureDateByAddingMonths = (date: Date, month: number): Date => {
    const startingMoment = moment(date);
    const futureDate = startingMoment.add(month, 'months');
    return futureDate.toDate()
}

export const isToday = (date: Date): boolean => {
    const today = moment();
    return moment(date).date() === today.date() &&
        moment(date).month() === today.month() &&
        moment(date).year() === today.year();
};

export const isPastDate = (date: Date): boolean => {
    return moment(date).isBefore(moment(), 'day');
};

export const compareDates = (date1: string | Date, date2: string | Date) => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);

    if (momentDate1.isSame(momentDate2, 'day')) {
        return 'equal';
    } else if (momentDate1.isBefore(momentDate2, 'day')) {
        return 'less';
    } else if (momentDate1.isAfter(momentDate2, 'day')) {
        return 'greater';
    }
};

export const DateFormat = {
    FullDateTime: 'YYYY-MM-DD HH:mm:ss',
    BA_DateDescription: 'ddd DD MMM YYYY',
    HourMinutes24Hrs: 'HH:mm',
    HourMinutes12Hrs: 'h:mm A',
    DDMMYY_Slash: 'DD/MM/YYYY',
    dddd_Do_MMMM_YYYY: 'dddd Do MMMM YYYY',
    MMMM_YYYY: 'MMMM YYYY',
    ddd_DD_MMMM_YYYY: 'ddd DD MMMM YYYY'
} as const;

export const getTodayDateTimeString = (format: string) => {
    return moment(new Date()).format(format)
}

export const getTimeDifferenceInMinutes = (inputTime: string): number => {
    const [inputHours, inputMinutes] = inputTime.split(':').map(Number);

    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();

    const inputDate = new Date(now);
    inputDate.setHours(inputHours, inputMinutes, 0, 0);

    const currentDate = new Date(now);
    currentDate.setHours(currentHours, currentMinutes, 0, 0);

    const differenceInMilliseconds = currentDate.getTime() - inputDate.getTime();
    const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
    return differenceInMinutes;
};

export const convertTo12HourFormat = (time: string): string => {
    return moment(time, DateFormat.HourMinutes24Hrs).format(DateFormat.HourMinutes12Hrs);
};

export function getStartAndEndDateTime(date: Date) {
    const now = new Date();
    let startDateTime = ''
    if(date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()) {
        startDateTime = getDateInISOString(new Date())
    } else {
        const startDate = date
        startDate.setHours(0, 0, 0, 0);
        startDateTime = getDateInISOString(startDate)
    }
    const eod = date
    eod.setHours(23, 59, 59, 999);
    return {startDateTime: startDateTime, endDateTime: getDateInISOString(eod)}
}

export function getDateInISOString(date: Date): string {
    const pad = (num: number): string => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}

export function getEndOfDayAfter21DaysFromNowInISOString(): string {
    const eod = new Date();
    eod.setDate(eod.getDate() + 21);
    eod.setHours(23, 59, 59, 999);
    return getDateInISOString(eod);
}

export function getEndOfDayInISOString(): string {
    const todayEOD = new Date();
    todayEOD.setHours(23, 59, 59, 999);
    return getDateInISOString(todayEOD);
}

export function getMonthStartAndEndDates(date: Date): { startDateTime: string; endDateTime: string } {
    const now = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();

    let start: Date;

    if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()) {
        start = new Date();
    } else {
        start = new Date(year, month, 1, 0, 0);
    }
    const end = new Date(year, month + 1, 0, 23, 59);
    const formattedStartDateTime = getDateInISOString(start)
    const formattedEndDateTime = getDateInISOString(end)
    return { startDateTime: formattedStartDateTime, endDateTime: formattedEndDateTime };
}

export function addMinutesToTime(time: string, minutesToAdd: string): string {
    const minutes = parseInt(minutesToAdd, 10);
    if (isNaN(minutes)) {
        return '';
    }
    const timeMoment = moment(time, DateFormat.HourMinutes24Hrs);
    timeMoment.add(minutesToAdd, 'minutes');
    return timeMoment.format(DateFormat.HourMinutes24Hrs);
}

export function formatDateString(dateString: string, inputDateFormat: string, outputDateFormat: string): string {
    const date = moment(dateString, inputDateFormat);
    return date.format(outputDateFormat);
}
