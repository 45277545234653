/* CheckInSuccessScreen.tsx */

import { ButtonText, Strings } from '../../../../constants/StringConstant';
import { ColorConstant } from '../../../../constants/ColorConstant';
import { AppointmentUIEntity } from '../../../../viewModels/CheckInViewModel';
import { CheckInSuccessScreenContainer, CheckInSuccessScreenDivider, CheckInSuccessScreenHiText, CheckInSuccessScreenInnerContainer, CheckInSuccessScreenSingleSlotContainer, CheckInSuccessScreenSingleSlotInnerContainer, CheckInSuccessScreenTitleView, CheckInSuccessScreenUserNameContainer, CheckInSuccessScreenUserNameText, DirectionalMessagesViewContainer, DirectionalMessageText, CheckInSuccessScreenMultiSlotsView, SingleSlotOnTimeText, SingleSlotSessionHolderNameText, SingleSlotTimeText, SingleSlotWithText, StylesCheckCircleOutlineRoundedIcon, CheckInSuccessScreenFooterView, CheckInSuccessScreenFooterButton, CheckInSuccessScreenMessageContainer, CheckInSuccessMessageVerticalLine, CheckInSuccessScreenMessageInnerContainer, CheckInSuccessScreenMessageTitleText, CheckInSuccessScreenMessagesContainer, MessageItemView, CheckInSuccessMessageInnerVerticalLine, CheckInSuccessMessageText, CheckInSuccessScreenTitleText } from '../../styles/StylesCheckIn';
import { DummyContainer } from '../../styles/StylesMatchingScreen';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Message {
    text: string,
    type: 'standard' | 'directional' | 'important',
}

interface CheckInSuccessScreenProps {
    patientName: string,
    handleDoneAction: () => void,
    selectedAppointments: AppointmentUIEntity[],
}
const MessageView: React.FC<{ messages: Message[] }> = ({ messages }) => {
    const { t } = useTranslation();
    return (
        <CheckInSuccessScreenMessageContainer>
            <CheckInSuccessMessageVerticalLine />
            <CheckInSuccessScreenMessageInnerContainer>
                <CheckInSuccessScreenMessageTitleText>
                    {t(Strings.CheckIn.WeWouldLikeToLetYouKnow)}
                </CheckInSuccessScreenMessageTitleText>
                <CheckInSuccessScreenMessagesContainer>
                    {messages.map((message, index) => (
                        <MessageItemView key={`${index.toString()}.${message.text}`}>
                            <CheckInSuccessMessageInnerVerticalLine 
                                style={{ backgroundColor: message.type === 'important' ? ColorConstant.RED_POWER : ColorConstant.TROLLEY_GREY }}>
                            </CheckInSuccessMessageInnerVerticalLine>
                            <CheckInSuccessMessageText
                                style={{ color: message.type === 'important' ? ColorConstant.RED_POWER : ColorConstant.IRON, fontWeight: message.type === 'important' ? 'bold' : '500' }}>
                                {`${t(Strings.CheckIn.IMPORTANT)}: ${message.text}`}
                            </CheckInSuccessMessageText>
                        </MessageItemView>
                    ))}
                </CheckInSuccessScreenMessagesContainer>
            </CheckInSuccessScreenMessageInnerContainer>
        </CheckInSuccessScreenMessageContainer>
    )
}

interface CheckedInSlotsListViewProps {
    appointments: AppointmentUIEntity[],
}

interface CheckedListViewProps {
    appointment: AppointmentUIEntity;
    width: number
}

const HeaderView: React.FC<{ patientName: string }> = ({ patientName }) => {
    const { t } = useTranslation();
    return (
        <DummyContainer>
            <CheckInSuccessScreenUserNameContainer>
                <CheckInSuccessScreenHiText>
                    {t(Strings.Hi)}
                </CheckInSuccessScreenHiText>
                <CheckInSuccessScreenUserNameText>
                    {patientName}
                </CheckInSuccessScreenUserNameText>
            </CheckInSuccessScreenUserNameContainer>

            <CheckInSuccessScreenTitleView>
                <StylesCheckCircleOutlineRoundedIcon />
                <CheckInSuccessScreenTitleText>{t(Strings.CheckIn.YouHaveCheckedInForYourAppointment)}</CheckInSuccessScreenTitleText>
            </CheckInSuccessScreenTitleView>
            <CheckInSuccessScreenDivider />
        </DummyContainer>
    )
}

const DirectionalMessagesView: React.FC<{ directionalMessages: Message[] }> = ({ directionalMessages }) => {
    return (
        <DirectionalMessagesViewContainer>
            {directionalMessages.map((message, index) => (
                <DirectionalMessageText key={`${index.toString()}.${message.text}`}>
                    {message.text}
                </DirectionalMessageText>
            ))}
        </DirectionalMessagesViewContainer>
    )
}

const CheckedListView: React.FC<CheckedListViewProps> = (props: CheckedListViewProps) => {
    const { t } = useTranslation();
    return (
        <CheckInSuccessScreenSingleSlotContainer sx={{ width: `${props.width}%` }}>
            <CheckInSuccessScreenSingleSlotInnerContainer>
                <SingleSlotTimeText>{props.appointment.appointmentTime}</SingleSlotTimeText>
                <SingleSlotWithText>{t(Strings.CheckIn.with)}</SingleSlotWithText>
                <SingleSlotSessionHolderNameText>{props.appointment.sessionHolderName}</SingleSlotSessionHolderNameText>
            </CheckInSuccessScreenSingleSlotInnerContainer>
            <SingleSlotOnTimeText>
                {props.appointment.waitingTime && props.appointment.waitingTime > 0 ? t(Strings.CheckIn.RunningMinutesLate).replace('##', props.appointment.waitingTime.toString()) : t(Strings.CheckIn.OnTime)}
            </SingleSlotOnTimeText>
        </CheckInSuccessScreenSingleSlotContainer>
    )
}

const CheckedInSlotsListView: React.FC<CheckedInSlotsListViewProps> = (props: CheckedInSlotsListViewProps) => {
    if (props.appointments.length === 1) {
        const appointment = props.appointments[0]
        return (<CheckedListView appointment={appointment} width={95} />)
    } else {
        return (
            <CheckInSuccessScreenMultiSlotsView>
                <Grid container justifyContent={'left'}>
                    {props.appointments.map((appointment, index) => (
                        <CheckedListView
                            key={`${index.toString()}.${appointment.appointmentId}`}
                            appointment={appointment}
                            width={props.appointments.length % 2 === 0 ? 45 : 100}
                        />
                    ))}
                </Grid>
            </CheckInSuccessScreenMultiSlotsView>
        )
    }
}

const FooterView: React.FC<{ handleDoneAction: () => void }> = ({ handleDoneAction }) => {
    const { t } = useTranslation();
    return (
        <CheckInSuccessScreenFooterView>
            <CheckInSuccessScreenFooterButton onClick={handleDoneAction}>
                {t(ButtonText.Done)}
            </CheckInSuccessScreenFooterButton>
        </CheckInSuccessScreenFooterView>
    )
}

const CheckInSuccessScreen: React.FC<CheckInSuccessScreenProps> = (props: CheckInSuccessScreenProps) => {

    const messages: Message[] = [{ text: 'Keep the surgical area clean and dry. Change the bandage daily or as directed.', type: 'important' },
    { text: 'Avoid putting weight on your knee for the first 48 hours. Use crutches as directed.', type: 'standard' },
    { text: 'Take prescribed pain medications as needed. Do not exceed the recommended dosage.', type: 'standard' },
    { text: 'Begin gentle range-of-motion exercises as instructed by your physical therapist.', type: 'standard' },
    { text: 'Monitor for signs of infection, such as increased redness, swelling, warmth, or discharge at the surgical site. Contact us if you notice any of these symptoms.', type: 'important' },
    { text: 'Your follow-up appointment is scheduled for July 20, 2024, at 10 AM.', type: 'standard' },
    { text: 'Measure your blood pressure daily at the same time each day. Record the readings in your blood pressure log.', type: 'standard' },
    { text: 'Take your prescribed blood pressure medication daily. Do not skip doses.', type: 'standard' },
    { text: 'Follow a low-sodium diet. Avoid processed foods and add more fruits and vegetables to your meals.', type: 'standard' },
    { text: 'Engage in at least 30 minutes of moderate exercise, such as walking, most days of the week.', type: 'standard' },
    { text: 'Contact us if your blood pressure readings are consistently above 140/90 mmHg, or if you experience severe headaches, chest pain, shortness of breath, or any unusual symptoms.', type: 'important' },
    { text: 'If you experience any side effects such as rash, itching, or difficulty breathing, contact your healthcare provider immediately.', type: 'standard' },
    { text: 'Do not skip doses; if you miss a dose, take it as soon as you remember unless it is almost time for your next dose.', type: 'standard' },
    { text: 'Please take a seat in waiting area A', type: 'directional' },
    ]

    const directionalMessages = messages.filter(message => message.type === 'directional')

    return (
        <CheckInSuccessScreenContainer>
            <CheckInSuccessScreenInnerContainer>
                <HeaderView patientName={props.patientName} />
                {directionalMessages.length > 0 ? <DirectionalMessagesView directionalMessages={messages.filter(message => message.type === 'directional')} /> : null}
                <CheckedInSlotsListView
                    appointments={props.selectedAppointments}
                />
                <FooterView handleDoneAction={props.handleDoneAction} />
            </CheckInSuccessScreenInnerContainer>
            {messages.length > 0 ? <MessageView messages={messages.filter(message => message.type !== 'directional')} /> : null}
        </CheckInSuccessScreenContainer>
    )
}

export default CheckInSuccessScreen;