import { store } from "../redux/Store";
import { AppConstants } from "../utils/constants/AppConstants";
import { isEmpty } from "../helpers/StringHelper";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { APIRepoKeys } from "../api/APIRepoKeys";
import { apiContainer } from "../api/APIContainer";
import { ITokenRepository } from "../api/interfaces/ITokenRepository";
import { setTokenDetails } from "../redux/actions/TokenAction";
import { TokenResult } from "../api/apiResultModels/TokenResult";

export const LoginViewModel = () => {

    const tokenRepository = apiContainer.get<ITokenRepository>(
        APIRepoKeys.TOKEN_API_REPOSITORY,
    );

    const validateSession = (dispatch: Dispatch<UnknownAction>): Promise<boolean> => {
        return new Promise((resolve) => {
            if (LoginViewModel().isUserSessionValid()) {
                resolve(true)
                return
            }
            LoginViewModel().geToken()
                .then(response => {
                    const tokenResult: TokenResult = JSON.parse(response)
                    if (tokenResult.access_token && tokenResult.expires_in) {
                        dispatch(setTokenDetails(tokenResult))
                        setTimeout(() => { resolve(true) }, 500);
                    } else {
                        resolve(false)
                    }
                })
                .catch((error: Error) => {
                    resolve(false)
                })
        })
    };

    const geToken = (): Promise<string> => {
        return tokenRepository.getToken();
    };

    function isUserSessionValid() {
        const tokenCreatedDateTimeString = store.getState().tokenSlice.tokenCreatedDateTime;
        if (isEmpty(tokenCreatedDateTimeString)) {
            return false
        }
        const tokenCreatedDateTime = new Date(tokenCreatedDateTimeString)
        const tokenExpiresIn = store.getState().tokenSlice.tokenExpiresIn;
        if (tokenCreatedDateTime && tokenExpiresIn) {
            const tokenExpireDuration = tokenExpiresIn - AppConstants.QuarterHourInSeconds
            const tokenCreatedDateTimeInSecond = ((new Date()).getTime() - tokenCreatedDateTime.getTime()) / 1000;
            return tokenExpireDuration > tokenCreatedDateTimeInSecond
        } else {
            return false
        }
    }

    return {
        validateSession,
        geToken,
        isUserSessionValid
    }
}