import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StyledAppBar, NavBarDateTimeSection, NavBarDateText, NavBarTimeText, NavBarBackDiv, NavBarHomeButton, StyledHomeIcon, NavBarBackButton, StyledChevronLeftIconIcon, StyledLanguageDiv, NavBarLanguageButton, StyledLanguageIcon, NavBarLanguageText, StyledLanguageEmptyDiv, NavBarBackText } from '../styles/StylesCommonView';
import { store, useAppDispatch, useAppSelector } from '../../../redux/Store';
import { RouterName } from '../../../navigation/RouterName';
import { ButtonText } from '../../../constants/StringConstant';
import { setShowLanguageScreen } from '../../../redux/actions/GlobalAction';
import { getLanguageText, resetToDefaultLanguage } from '../../../helpers/CommonHelper';
import { useTranslation } from 'react-i18next';

export interface TopNavigatorProps {
    isShowHomeButton: boolean
    isShowBackOption: boolean
    handleBackClick: () => void
}

const NavigationBarHomeButton: React.FC<{ isMobile: boolean, isPortrait: boolean, onClickHomeButton: () => void }> = ({ isMobile, isPortrait, onClickHomeButton }) => {
    return (
        <NavBarHomeButton style={{ marginRight: isMobile || isPortrait  ? 1 : 5, ...(isMobile ? { height: 40 } : { height: 'auto' }) }} onClick={onClickHomeButton}>
            <StyledHomeIcon style={{ fontSize: isMobile ? 25 : 40 }} />
        </NavBarHomeButton>
    )
}

const NavigationBarBackButton: React.FC<{ isMobile: boolean, isPortrait: boolean, onClickBackButton: () => void }> = ({ isMobile, isPortrait, onClickBackButton }) => {
    const { t } = useTranslation();
    return (
        <NavBarBackButton
            style={{
                ...(isMobile || isPortrait ? { aspectRatio: '1 / 1' } : { width: 'auto' }),
                ...(isMobile ? { height: 40 } : { height: 'auto' })
            }} onClick={onClickBackButton}
        >
            <StyledChevronLeftIconIcon style={{ fontSize: isMobile ? 35 : 50 }} />
            {!(isMobile || isPortrait) ?
                <NavBarBackText variant="button">{t(ButtonText.Back)}</NavBarBackText> : null
            }
        </NavBarBackButton>
    )
}

const NavigationBarLanguageButton: React.FC<{ isMobile: boolean, isPortrait: boolean, handleLanguageSelection: () => void, langText: string }> = ({ isMobile, isPortrait, handleLanguageSelection, langText }) => {
    return (
        <NavBarLanguageButton
            style={{
                ...(isMobile ? { height: 40 } : { height: 'auto' }),
                ...(isMobile ? { alignSelf: 'center' } : { alignSelf: 'auto' })
            }}
            onClick={handleLanguageSelection}
        >
            <StyledLanguageIcon
                style={{
                    fontSize: isMobile ? 25 : 30,
                    marginLeft: isMobile || isPortrait ? '0px' : '8px'
                }}
            />
            <NavBarLanguageText isMobile={isMobile}> {langText} </NavBarLanguageText>
        </NavBarLanguageButton>
    )
}

function navBarDateTextFontSize(isMobile: boolean, isPortrait: boolean) {
    let navBarDateTextFontSize: number;
    if (isPortrait) {
        navBarDateTextFontSize = 20;
    } else if (isMobile) {
        navBarDateTextFontSize = 15;
    } else {
        navBarDateTextFontSize = 25;
    }
    return navBarDateTextFontSize
}

const TopBar: React.FC<TopNavigatorProps> = (props: TopNavigatorProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPortrait = useMediaQuery('(orientation: portrait)');

    const currentDate = useAppSelector((state) => state.globalSlice.currentDate)
    const currentTime = useAppSelector((state) => state.globalSlice.currentTime)
    const selectedLanguage = useAppSelector((state) => state.globalSlice.selectedLanguage)
    const isShowLanguageOption = useAppSelector((state) => state.configSlice.allConfig?.languages ?? []).length > 1
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    function onClickHomeButton() {
        const languages = store.getState().configSlice.allConfig?.languages ?? []
        resetToDefaultLanguage(dispatch, languages)
        navigate(RouterName.Home)
    }

    return (
        <StyledAppBar mobile={isMobile}>
            <NavBarDateTimeSection mobile={isMobile} portrait={isPortrait}>
                <NavBarDateText style={{ fontSize: navBarDateTextFontSize(isMobile, isPortrait) }}>{currentDate}</NavBarDateText>
                <NavBarTimeText mobile={isMobile} portrait={isPortrait} style={{ fontSize: navBarDateTextFontSize(isMobile, isPortrait) }}>{currentTime}</NavBarTimeText>
            </NavBarDateTimeSection>

            <NavBarBackDiv mobile={isMobile} >
                {props.isShowHomeButton ?
                    <NavigationBarHomeButton isMobile={isMobile} isPortrait={isPortrait} onClickHomeButton={onClickHomeButton}/>
                    : null
                }
                {props.isShowBackOption ?
                    <NavigationBarBackButton isMobile={isMobile} isPortrait={isPortrait} onClickBackButton={props.handleBackClick} />
                    : null
                }
            </NavBarBackDiv>

            {isShowLanguageOption ?
                <StyledLanguageDiv mobile={isMobile}>
                    <NavigationBarLanguageButton 
                        isMobile={isMobile} 
                        isPortrait={isPortrait} 
                        handleLanguageSelection={() => dispatch(setShowLanguageScreen(true))} 
                        langText={getLanguageText(selectedLanguage?.languageName)}
                    />
                </StyledLanguageDiv>
                : <StyledLanguageEmptyDiv mobile={isMobile} />
            }
        </StyledAppBar>
    );
};

export default TopBar;
