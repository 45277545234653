/* SessionHolderNameListView */

import { ListItemText } from "@mui/material";
import { SlotHolderNameList, SlotHolderNameListItem } from "../../../styles/StylesBookAppointment";
import { Holder } from "../../../../../api/apiResultModels/AppointmentSessionsResult";

interface SessionHolderNameListViewProps {
    holders: Holder[]
    selectedSessionHolder: Holder | undefined;
    handleSessionHolderSelection: (sessionHolder: Holder) => void
}

const SessionHolderNameListView: React.FC<SessionHolderNameListViewProps> = (props: SessionHolderNameListViewProps) => {
    return (
        <SlotHolderNameList>
            {props.holders.map((sessionHolder, index) => {
                const isSelected = props.selectedSessionHolder?.dbid === sessionHolder.dbid
                return (
                    <SlotHolderNameListItem key={`${index}.${sessionHolder.dbid}`} isSelected={isSelected} onClick={() => props.handleSessionHolderSelection(sessionHolder)}>
                        <ListItemText
                            primary={`${sessionHolder.title}. ${sessionHolder.firstNames} ${sessionHolder.lastName}`}
                            primaryTypographyProps={{ sx: { fontSize: 'larger' } }}
                        />
                    </SlotHolderNameListItem>
                );
            })}
        </SlotHolderNameList>
    );
};

export default SessionHolderNameListView
