/* GlobalReducer.ts */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Language, Organisation } from '../../models/Config';

export type GlobalState = {
    orgs: Organisation[];
    currentOrg: Organisation | undefined;
    currentDate: string;
    currentTime: string;
    showLanguageScreen: boolean;
    selectedLanguage: Language | undefined;
    apiCallInProgress: boolean
}

const initialState: GlobalState = {
    orgs: [],
    currentOrg: undefined,
    currentDate: '',
    currentTime: '',
    showLanguageScreen: false,
    selectedLanguage: undefined,
    apiCallInProgress: false
}

export const globalSlice = createSlice({
    name: 'global',
    initialState: initialState,
    reducers: {
        setOrgs: (state, action: PayloadAction<Organisation[]>) => {
            state.orgs = action.payload;
        },
        setCurrentOrg: (state, action: PayloadAction<Organisation | undefined>) => {
            state.currentOrg = action.payload;
        },
        setCurrentDate: (state, action: PayloadAction<string>) => {
            state.currentDate = action.payload;
        },
        setCurrentTime: (state, action: PayloadAction<string>) => {
            state.currentTime = action.payload;
        },
        setShowLanguageScreen: (state, action: PayloadAction<boolean>) => {
            state.showLanguageScreen = action.payload;
        },
        setSelectedLanguage: (state, action: PayloadAction<Language>) => {
            state.selectedLanguage = action.payload;
        },
        setAPICallInProgress: (state, action: PayloadAction<boolean>) => {
            state.apiCallInProgress = action.payload;
        },
    }
})

export default globalSlice.reducer;