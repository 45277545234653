/* MatchingContainer.tsx */

import React from 'react';
import { Strings } from '../../../../constants/StringConstant';
import { MatchTitle } from '../../../../enum';
import { isEqualIgnoreCase } from '../../../../helpers/StringHelper';
import { validateDOB } from '../../../../helpers/ValidationHelper';
import { AppUtil } from '../../../../Util';
import { MatchingViewModel } from '../../../../viewModels/MatchingViewModel';
import PostalCodeComponent from './PostalCodeComponent';
import DateComponent from './dob/DateComponent';
import { useTranslation } from 'react-i18next';
import DayGridComponent from './DayGridComponent';
import { padDigitsInPrefix } from '../../../../helpers/CommonHelper';
import YearGridComponent from './YearGridComponent';
import MonthGridComponent from './MonthGridComponent';
import GenderComponent from './GenderComponent';
import { HeaderQuestionContainer, MatchingContainer, MatchingInnerContainer } from '../../styles/StylesMatchingScreen';
import HeaderQuestion from '../../commonViews/HeaderQuestion';
import CharacterGridComponent from './CharacterGridComponent';

export interface MatchingComponents {
    date: string,
    day: string,
    month: string,
    year: string,
    gender: string,
    surNameFirstLetter: string,
    postalCode: string,
}

interface MatchingContainerScreenProps {
    matchingComponents: MatchingComponents;
    matchTitles: string[]
    showPostalCodes: boolean
    postalCodes: string[]
    onClickPostCode: (matchingFields: MatchingComponents) => (void)
    onCompletionMatchingContainer: (matchingFields: MatchingComponents) => (void)
    currentIndex: number
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
    onNoneOfTheAboveClickOnPostCode: (matchingFields: MatchingComponents) => (void)
}

const MatchingContainerScreen: React.FC<MatchingContainerScreenProps> = (props: MatchingContainerScreenProps) => {
    const [matchingComponents, setMatchingComponents] = React.useState<MatchingComponents>(props.matchingComponents)
    const [currentMatchTitle, setCurrentMatchTitle] = React.useState<MatchTitle>()
    const [headerText, setHeaderText] = React.useState<string>('')
    const [subHeaderText, setSubHeaderText] = React.useState<string>('')
    const { t } = useTranslation();
    const years = MatchingViewModel().getYears()

    React.useEffect(() => {
        const currentMatchTitle = AppUtil.stringToEnum(props.matchTitles[props.currentIndex], MatchTitle)
        setCurrentMatchTitle(currentMatchTitle)
    }, [props.currentIndex, props.matchTitles])

    React.useEffect(() => {
        if(props.showPostalCodes) {
            setHeaderText(t(Strings.Matching.WhatIsYourPostalCode))
            setSubHeaderText('')
        } else if (currentMatchTitle) {
            const bannerComponents = MatchingViewModel().getBannerText(currentMatchTitle)
            setHeaderText(t(bannerComponents.headerText))
            setSubHeaderText(t(bannerComponents.subHeaderText))
        }
    }, [currentMatchTitle, props.showPostalCodes, t])

    const updateCurrentComponent = (matchingComponents: MatchingComponents) => {
        const nextIndex = props.currentIndex + 1
        if (nextIndex === props.matchTitles.length) {
            setMatchingComponents(matchingComponents)
            props.onCompletionMatchingContainer(matchingComponents)
        } else {
            const currentMatchTitle = AppUtil.stringToEnum(props.matchTitles[nextIndex], MatchTitle)
            setCurrentMatchTitle(currentMatchTitle)
            setMatchingComponents(matchingComponents)
            props.setCurrentIndex(nextIndex)
        }
    }

    const setDate = (date: string) => {
        const updatedMatchingComponents = { ...matchingComponents, date: date }
        updateCurrentComponent(updatedMatchingComponents)
    }

    const setDay = (day: string) => {
        const updatedMatchingComponents = { ...matchingComponents, day: day }
        updateCurrentComponent(updatedMatchingComponents)
    }

    const setMonth = (month: string) => {
        const updatedMatchingComponents = { ...matchingComponents, month: month }
        updateCurrentComponent(updatedMatchingComponents)
    }

    const setYear = (year: string) => {
        const updatedMatchingComponents = { ...matchingComponents, year: year }
        updateCurrentComponent(updatedMatchingComponents)
    }

    const setGender = (gender: string) => {
        const updatedMatchingComponents = { ...matchingComponents, gender: gender }
        updateCurrentComponent(updatedMatchingComponents)
    }

    const setSurNameFirstCharacter = (surNameFirstLetter: string) => {
        const updatedMatchingComponents = { ...matchingComponents, surNameFirstLetter: surNameFirstLetter }
        updateCurrentComponent(updatedMatchingComponents)
    }

    return (
        <MatchingContainer>
            <HeaderQuestionContainer>
                <HeaderQuestion headerText={headerText} subHeaderText={subHeaderText} />
            </HeaderQuestionContainer>
            <MatchingInnerContainer>
                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Date) && !props.showPostalCodes ?
                    <DateComponent
                        onNextClick={(date) => {
                            if (validateDOB(date)) {
                                setDate(date)
                            }
                        }}
                        dateComponent={MatchingViewModel().constructDateComponent(matchingComponents.date, '-')}
                    /> : null
                }

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.SurName) && !props.showPostalCodes ?
                    <CharacterGridComponent
                        onClick={(character) => setSurNameFirstCharacter(character)}
                        gridAlignment={'left'}
                    /> : null
                }

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Day) && !props.showPostalCodes ?
                    <DayGridComponent
                        gridAlignment={'left'}
                        onDayClick={(date) => setDay(padDigitsInPrefix(date, 2))}
                    /> : null
                }

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Month) && !props.showPostalCodes ?
                    <MonthGridComponent
                        gridAlignment={'left'}
                        onMonthClick={(month) => setMonth(padDigitsInPrefix(month, 2))}
                    /> : null
                }

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Year) && !props.showPostalCodes ?
                    <YearGridComponent
                        years={years}
                        onYearClick={(selectedIndex) => setYear(years[selectedIndex])}
                        onNoneOfTheAboveClick={() => setYear('')}
                        footerText={t(Strings.Matching.NoneOfTheAbove)}
                        gridAlignment={'left'}
                    /> : null
                }

                {isEqualIgnoreCase(currentMatchTitle, MatchTitle.Gender) && !props.showPostalCodes ?
                    <GenderComponent
                        onGenderClick={(gender) => setGender(gender)}
                        gridAlignment={'center'}
                        declineText={t(Strings.Matching.RatherNotSay)}
                    /> : null
                }

                {props.showPostalCodes && props.postalCodes.length > 0 ?
                    <PostalCodeComponent
                        gridAlignment={'left'}
                        onClick={(postalCode) => {
                            props.onClickPostCode({ ...matchingComponents, postalCode: postalCode })
                        }}
                        postalCodes={props.postalCodes}
                        footerText={t(Strings.Matching.NoneOfTheAbove)}
                        onNoneOfTheAboveClick={() => props.onNoneOfTheAboveClickOnPostCode({...matchingComponents, postalCode: ''})}
                    /> : null
                }
            </MatchingInnerContainer>

        </MatchingContainer>
    );
}

export default MatchingContainerScreen;
