/* AppUtil.ts */

export class AppUtil {

    static stringToEnum<T extends Record<string, string>>(str: string, enumObj: T): T[keyof T] | undefined {
        const enumValues = Object.values(enumObj);
        if (enumValues.includes(str)) {
            return str as T[keyof T];
        }
        return undefined;
    }

}