import { Container } from "inversify";
import "reflect-metadata";
import { APIRepoKeys } from "./APIRepoKeys";
import { TokenRepository } from "./repositories/TokenRepository";
import { PatientRepository } from "./repositories/PatientRepository";
import { AppointmentRepository } from "./repositories/AppointmentRepository";

const _container = new Container();
_container.bind(APIRepoKeys.TOKEN_API_REPOSITORY).to(TokenRepository).inSingletonScope();
_container.bind(APIRepoKeys.PATIENT_API_REPOSITORY).to(PatientRepository).inSingletonScope();
_container.bind(APIRepoKeys.APPOINTMENT_API_REPOSITORY).to(AppointmentRepository).inSingletonScope();
export const apiContainer = _container;