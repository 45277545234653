/* AppointmentListScreen.tsx */

import React from 'react'
import { Strings, ButtonText } from '../../../../../constants/StringConstant'
import { store } from '../../../../../redux/Store'
import { AppointmentListScreenContainer, AppointmentListScreenHeaderDivider } from '../../../styles/StylesCheckIn'
import AppointmentListHeaderView from './AppointmentListHeaderView'
import AppointmentListView from './AppointmentListView'
import { AppointmentListFooter } from './AppointmentListFooterView'
import { useTranslation } from 'react-i18next'
import { AppointmentUIEntity, CheckInViewModel } from '../../../../../viewModels/CheckInViewModel'

interface AppointmentListScreenProps {
    patientName: string,
    handleNotYouAction: () => void,
    handleCloseAction: () => void,
    handleCheckInAction: (selectedAppointments: AppointmentUIEntity[]) => void,
    appointments: AppointmentUIEntity[]
}

const AppointmentListScreen: React.FC<AppointmentListScreenProps> = (props: AppointmentListScreenProps) => {
    const [showCloseButton, setShowCloseButton] = React.useState<boolean>(false)
    const [showCheckInButton, setShowCheckInButton] = React.useState<boolean>(false)
    const [showAppointmentTitle, setShowAppointmentTitle] = React.useState<boolean>(true)
    const { t } = useTranslation();
    const [selectedAppointments, setSelectedAppointments] = React.useState<AppointmentUIEntity[]>(props.appointments.length === 1 && props.appointments.some(appointment => !appointment.isEarlyAppointment && !appointment.isLateAppointment) ? [props.appointments[0]] : [])
    const earlyArrival = store.getState().configSlice.earlyArrival ?? 0

    const sortedAppointments = CheckInViewModel().sortSlotsBasedOnTime(props.appointments)

    React.useEffect(() => {
        setShowCheckInButton(!showCloseButton)
    }, [selectedAppointments, showCloseButton])

    React.useEffect(() => {
        const isAnyValidAppointment = props.appointments.some(appointment => !appointment.isEarlyAppointment && !appointment.isLateAppointment)
        setShowCloseButton(!isAnyValidAppointment)
        setShowAppointmentTitle(isAnyValidAppointment)
    }, [props.appointments, selectedAppointments])


    const getFooterTitle = () => {
        if (props.appointments.length === 1 && props.appointments.some(appointment => !appointment.isEarlyAppointment && !appointment.isLateAppointment)) {
            return `${t(ButtonText.CheckIn)} >`
        } else if (selectedAppointments.length === 0) {
            return t(Strings.CheckIn.Check_in)
        } else if (selectedAppointments.length === 1) {
            return t(Strings.CheckIn.CheckInForOneAppointment)
        } else {
            return t(Strings.CheckIn.CheckInForMoreAppointments).replace('##', `${selectedAppointments.length}`)
        }
    }

    return (
        <AppointmentListScreenContainer>

            <AppointmentListHeaderView
                patientName={props.patientName}
                handleNotYouAction={props.handleNotYouAction}
            />

            <AppointmentListScreenHeaderDivider />

            <AppointmentListView
                showAppointmentTitle={showAppointmentTitle}
                appointments={sortedAppointments}
                earlyArrival={earlyArrival}
                selectedAppointments={selectedAppointments}
                setSelectedAppointments={setSelectedAppointments}
            />

            {showCloseButton || showCheckInButton ?
                <AppointmentListFooter
                    footerTitle={getFooterTitle()}
                    showCloseButton={showCloseButton}
                    showCheckInButton={showCheckInButton}
                    handleCloseAction={props.handleCloseAction}
                    handleCheckInAction={() => {
                        if (selectedAppointments.length > 0) {
                            props.handleCheckInAction(selectedAppointments)
                        }
                    }}
                    selectedAppointments={selectedAppointments}
                    appointmentsLength={props.appointments.length}
                />
                : null
            }

        </AppointmentListScreenContainer>
    )

}

export default AppointmentListScreen