/* CharacterGridComponent.tsx */

import React from 'react';
import { Grid } from '@mui/material';
import { CharacterGridComponentContainer, SurnameGridButton } from '../../styles/StylesMatchingScreen';

interface GridProps {
    onClick: (item: string) => void;
    gridAlignment: 'center' | 'left' | 'right',
}

const alphabets = Array.from(Array(26), (_, i) => (String.fromCharCode(65 + i)));

const CharacterGridComponent: React.FC<GridProps> = (props: GridProps) => {

    return (
        <CharacterGridComponentContainer data-testid="CharacterGridComponentContainer">
            <Grid data-testid="CharacterGridComponentOuterGrid" container spacing={3} marginTop={2} justifyContent={props.gridAlignment}>
                {alphabets.map((alphabet, index) => {
                    return (
                        <Grid data-testid="CharacterGridComponentInnerGrid" item key={`${index}.${alphabet}`}>
                            <SurnameGridButton data-testid="SurnameGridButton"
                                onClick={() => props.onClick(alphabet)}
                                variant="contained"
                            >
                                {alphabet}
                            </SurnameGridButton>
                        </Grid>
                    )
                })}
            </Grid>
        </CharacterGridComponentContainer>

    );
};

export default CharacterGridComponent;