// src/locales/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationData from './translations.json'; // Import Translations JSON file

// Define a type for the translation data structure
interface TranslationData {
    [key: string]: {
        [languageCode: string]: string;
    };
}

// Define a type for the resources object
type Resources = {
    [languageCode: string]: {
        translation: {
            [key: string]: string;
        };
    };
};

// Define language mapping
const languageMapping: { [key: string]: string } = {
    en: 'en', // English
    es: 'es', // Spanish
    fr: 'fr', // French
    de: 'de', // German
    ru: 'ru', // Russian
    tr: 'tr', // Turkish
    zh: 'zh', // Chinese
    ar: 'ar', // Arabic
    el: 'el', // Greek
    hi: 'hi', // Hindi
    it: 'it', // Italian
    pl: 'pl', // Polish
    pt: 'pt', // Portuguese
    ro: 'ro', // Romanian
    sk: 'sk', // Slovakian
    th: 'th', // Thai
    ur: 'ur', // Urdu
    cy: 'cy', // Welsh
    sq: 'sq', // Albanian
    bn: 'bn', // Bengali
    so: 'so', // Somalian
    pa: 'pa', // Punjabi
    gu: 'gu', // Gujarati
    mis: 'mis', // Iraqi
    ku: 'ku', // Kurdish
    fa: 'fa', // Farsi
    ta: 'ta', // Tamil
    hu: 'hu', // Hungarian
    cs: 'cs', // Czech
    ne: 'ne', // Nepali
};

// Define Native text
export const languages: { [key: string]: string } = {
    English: "English",
    Spanish: "Español",
    French: "Français",
    German: "Deutsch",
    Russian: "Русский",
    Turkish: "Türkçe",
    Chinese: "中文",
    Arabic: "العربية",
    Greek: "ελληνικά",
    Hindi: "हिंदी",
    Italian: "Italiano",
    Polish: "Polski",
    Portuguese: "Português",
    Romanian: "Română",
    Slovakian: "slovenčina",
    Thai: "ไทย",
    Urdu: "اردو",
    Welsh: "Cymraeg",
    Albanian: "Shqip",
    Bengali: "বাংলা",
    Somalian: "Soomaali",
    Punjabi: "ਪੰਜਾਬੀ",
    Gujarati: "ગુજરાતી",
    Iraqi: "عربي عراقي",
    Kurdish: "کوردی (سۆرانی)",
    Farsi: "فارسی",
    Tamil: "தமிழ்",
    Hungarian: "Magyar",
    Czech: "Čeština",
    Nepali: "नेपाली"
  };

// Cast the imported JSON to the TranslationData type
const translationDataTyped: TranslationData = translationData as TranslationData;

// Convert JSON data into i18next format
const resources: Resources = Object.keys(languageMapping).reduce((acc: Resources, langCode: string) => {
    acc[langCode] = {
        translation: Object.keys(translationDataTyped).reduce((langs: { [key: string]: string }, phraseKey: string) => {
            const translation = translationDataTyped[phraseKey]?.[langCode];
            if (translation) {
                langs[phraseKey] = translation;
            }
            return langs;
        }, {})
    };
    return acc;
}, {});

// Initialize i18next
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en', // Default language
        fallbackLng: 'en', // Fallback language
        interpolation: {
            escapeValue: false // React already safes from xss
        }
    });

export default i18n;
