import { useTranslation } from "react-i18next";
import React from "react";
import { Strings } from "../../../../../../constants/StringConstant";
import { PatientInfoScreenHeader, PatientInfoScreenTopContainer, PatientInfoScreenUserNameContainer, ThanksText, PatientNameText, PatientInfoScreenDivider } from "../../../../styles/StylesCheckIn";

const PatientInfoHeaderView: React.FC<{ patientName: string }> = ({ patientName }) => {
    const { t } = useTranslation();
    return (
        <PatientInfoScreenHeader>
            <PatientInfoScreenTopContainer>
                <PatientInfoScreenUserNameContainer>
                    <ThanksText>
                        {t(Strings.Thanks)}
                    </ThanksText>
                    <PatientNameText>
                        {patientName}
                    </PatientNameText>
                </PatientInfoScreenUserNameContainer>
            </PatientInfoScreenTopContainer>
            <PatientInfoScreenDivider/>
        </PatientInfoScreenHeader>
    )
}

export default PatientInfoHeaderView