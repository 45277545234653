import { Grid } from "@mui/material"
import { BookAppointmentSlotSelectionSectionContainer, BookAppointmentSlotSelectionGridContainer, BookAppointmentSlotSelectionSessionText, BookAppointmentSlotSelectionGrid, SlotTimeGridButton } from "../../../styles/StylesBookAppointment"
import { Slot } from "../../../../../api/apiResultModels/SessionSlotsResult";

interface SlotListViewProps {
    slots: Slot[], 
    handleSlotSelection: (selectedSlot: Slot) => void
    siteName: string
}

const SlotListView: React.FC<SlotListViewProps> = (props: SlotListViewProps) => {
    return (
        <BookAppointmentSlotSelectionSectionContainer>
            <BookAppointmentSlotSelectionGridContainer>
                <BookAppointmentSlotSelectionSessionText>{props.siteName}</BookAppointmentSlotSelectionSessionText>
                <BookAppointmentSlotSelectionGrid>
                    <Grid container spacing={2} justifyContent={'left'}>
                        {props.slots.map((slot, index) => {
                            const slotTime = slot.startTime
                            return (
                                <Grid item key={`${index}.${slotTime}`}>
                                    <SlotTimeGridButton
                                        onClick={() => props.handleSlotSelection(slot)}
                                        variant="contained"
                                    >
                                        {slotTime}
                                    </SlotTimeGridButton>
                                </Grid>
                            )
                        })}
                    </Grid>
                </BookAppointmentSlotSelectionGrid>
            </BookAppointmentSlotSelectionGridContainer>
        </BookAppointmentSlotSelectionSectionContainer>
    )
}

export default SlotListView