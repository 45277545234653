/* BookAppointmentSuccess.tsx */

import { ButtonText, Strings } from '../../../../constants/StringConstant';
import { DateFormat, formatDateString } from '../../../../helpers/DateTimeHelper';
import { ColorConstant } from '../../../../constants/ColorConstant';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { SlotUIEntity } from '../../../../uiEntities/SlotUIEntity';
import { BookAppointmentSuccessContainer, BookAppointmentSuccessInnerContainer, BookAppointmentTopView, DividerInSuccess, DoneButtonInSuccess, FooterButtonViewInSuccess, SlotContentViewInSuccess, SlotDateTextInSuccess, SlotHolderNameTextInSuccess, SlotInfoViewInSuccess, SlotLocationTextInSuccess, SlotLocationTextInSuccessContainer, SlotTimeTextInSuccess, SlotTimeTypeViewInSuccess, SlotTypeTextInSuccess, SlotViewInSuccess, TitleTextInSuccess, TitleView } from '../../styles/StylesBookAppointment';
import { useTranslation } from 'react-i18next';

interface BookAppointmentSuccessProps {
    handleDoneAction: () => void
    selectedSlot: SlotUIEntity
}

const BookAppointmentSuccess: React.FC<BookAppointmentSuccessProps> = (props: BookAppointmentSuccessProps) => {
    const { t } = useTranslation();
    const formattedDate = props.selectedSlot.date ? formatDateString(props.selectedSlot.date, DateFormat.DDMMYY_Slash, DateFormat.dddd_Do_MMMM_YYYY) : ''
    const startTime = props.selectedSlot.startDateTime ? props.selectedSlot.startDateTime : ''
    const endTime = props.selectedSlot.endDateTime ? props.selectedSlot.endDateTime : ''
    return (
        <BookAppointmentSuccessContainer>
            <BookAppointmentSuccessInnerContainer>
                <BookAppointmentTopView>
                    <TitleView>
                        <CheckCircleOutlineRoundedIcon sx={{ color: ColorConstant.GREEN_SEDUCTION, fontSize: 55, }} />
                        <TitleTextInSuccess>{t(Strings.BookAppointment.YouHaveBookedYourAppointment)}</TitleTextInSuccess>
                    </TitleView>
                    <SlotContentViewInSuccess>
                        <SlotViewInSuccess>
                            <SlotInfoViewInSuccess>
                                <SlotDateTextInSuccess>{formattedDate}</SlotDateTextInSuccess>
                                <SlotTimeTypeViewInSuccess>
                                    <SlotTimeTextInSuccess>{`${startTime} - ${endTime}`}</SlotTimeTextInSuccess>
                                    <SlotTypeTextInSuccess>{`(${props.selectedSlot.slotTypeDescription})`}</SlotTypeTextInSuccess>
                                </SlotTimeTypeViewInSuccess>
                                <SlotHolderNameTextInSuccess>{`${props.selectedSlot.sessionHolderName}`}</SlotHolderNameTextInSuccess>
                            </SlotInfoViewInSuccess>
                            <DividerInSuccess />
                            <SlotLocationTextInSuccessContainer>
                                <SlotLocationTextInSuccess>{`${props.selectedSlot.locationDisplayName}`}</SlotLocationTextInSuccess>
                            </SlotLocationTextInSuccessContainer>
                        </SlotViewInSuccess>
                    </SlotContentViewInSuccess>
                </BookAppointmentTopView>
                <FooterButtonViewInSuccess>
                    <DoneButtonInSuccess onClick={props.handleDoneAction}>
                        {t(ButtonText.Done)}
                    </DoneButtonInSuccess>
                </FooterButtonViewInSuccess>
            </BookAppointmentSuccessInnerContainer>
        </BookAppointmentSuccessContainer>
    )
}


export default BookAppointmentSuccess;