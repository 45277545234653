/* NextAvailableAppointmentView */

import { useTranslation } from "react-i18next"
import { Strings } from "../../../../../constants/StringConstant"
import { formatDateString, DateFormat } from "../../../../../helpers/DateTimeHelper"
import { SlotUIEntity } from "../../../../../uiEntities/SlotUIEntity"
import { NextAvailableAppointmentContainer, RightSideScrollableContainer, SlotDateText, SlotTimeText, SlotTypeText, SessionHolderNameText, SlotDivider, SlotLocationText, BookThisButton } from "../../../styles/StylesBookAppointment"

const NextAvailableAppointmentView: React.FC<{ nextAvailableSlot: SlotUIEntity, handleBookThisAppointmentAction: (slot: SlotUIEntity) => void }> = ({ nextAvailableSlot, handleBookThisAppointmentAction }) => {
    const formattedDate = nextAvailableSlot.date ? formatDateString(nextAvailableSlot.date, DateFormat.DDMMYY_Slash, DateFormat.dddd_Do_MMMM_YYYY) : ''
    const startTime = nextAvailableSlot.startDateTime ?? ''
    const endTime = nextAvailableSlot.endDateTime ?? ''
    const { t } = useTranslation();
    return (
        <NextAvailableAppointmentContainer>
            <RightSideScrollableContainer>
                <SlotDateText>{formattedDate}</SlotDateText>
                <SlotTimeText>{`${startTime} - ${endTime}`}</SlotTimeText>
                <SlotTypeText>{`(${nextAvailableSlot.slotTypeDescription})`}</SlotTypeText>
                <SessionHolderNameText>{nextAvailableSlot.sessionHolderName}</SessionHolderNameText>
                <SlotDivider />
                <SlotLocationText>{nextAvailableSlot.locationDisplayName}</SlotLocationText>
                <BookThisButton variant="text"
                    onClick={() => handleBookThisAppointmentAction(nextAvailableSlot)}>
                    {t(Strings.BookAppointment.BookThisAppointment)}
                </BookThisButton>
            </RightSideScrollableContainer>
        </NextAvailableAppointmentContainer>
    )
}

export default NextAvailableAppointmentView