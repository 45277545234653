import { useTranslation } from "react-i18next";
import { ErrorMessage } from "../../../../../constants/StringConstant"
import { ErrorValidationContainer, ErrorValidationText } from "../../../styles/StylesMatchingScreen"

interface DateErrorValidationViewProps {
    showValidationError: boolean
}

const DateErrorValidationView: React.FC<DateErrorValidationViewProps> = (props: DateErrorValidationViewProps) => {
    const { t } = useTranslation();
    return (
        <div>
            {props.showValidationError ?
                <ErrorValidationContainer>
                    <ErrorValidationText>{t(ErrorMessage.PleaseEnterValidDateOfBirth)}</ErrorValidationText>
                </ErrorValidationContainer>
                : null
            }
        </div>
    )
}

export default DateErrorValidationView