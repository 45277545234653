/* HomeScreen.tsx */

import { useNavigate } from 'react-router-dom';
import { useAppSelector, store } from '../../../../redux/Store';
import TopBar from '../../commonViews/TopBar';
import { HomeScreenContainer } from '../../styles/StylesHome';
import { useMediaQuery, useTheme } from '@mui/material';
import { RouterName } from '../../../../navigation/RouterName';
import HomeScreenOptions from './HomeScreenOptionsView';
import HomeScreenTopBanner from './HomeScreenTopBanner';

const HomeScreen = () => {

  const navigate = useNavigate();
  const modules = useAppSelector((state) => state.configSlice.modules)
  const kioskTitle = store.getState().configSlice.allConfig?.Title ?? ''
  const kioskLogo = useAppSelector((state) => state.configSlice.allConfig?.kioskLogo ?? '')

  const handleCheckInAction = () => {
    navigate(RouterName.CheckIn);
  };

  const handleBookAppointmentAction = () => {
    navigate(RouterName.BookAppointment);
  };

  const siteMapAction = () => {
    
  };

  const questionnairesAction = () => {

  };

  const theme = useTheme();
  const portrait = useMediaQuery('(orientation: portrait)');
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <HomeScreenContainer>

      <TopBar
        isShowHomeButton={false}
        isShowBackOption={false}
        handleBackClick={() => { }}
      />

      <HomeScreenTopBanner kioskTitle={kioskTitle} kioskLogo={kioskLogo} />

      <HomeScreenOptions
        handleCheckInAction={handleCheckInAction}
        handleBookAppointmentAction={handleBookAppointmentAction}
        siteMapAction={siteMapAction}
        questionnairesAction={questionnairesAction}
        modules={modules}
        portrait={portrait}
        mobile={mobile}
      />

    </HomeScreenContainer>
  );
};

export default HomeScreen;
