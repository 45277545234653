// src/utils/InternetConnectivity.ts

class InternetConnectivity {
  private static instance: InternetConnectivity;
  private isOnline: boolean;

  private constructor() {
    this.isOnline = navigator.onLine;
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  }

  static getInstance(): InternetConnectivity {
    if (!InternetConnectivity.instance) {
      InternetConnectivity.instance = new InternetConnectivity();
    }
    return InternetConnectivity.instance;
  }

  private updateOnlineStatus = () => {
    this.isOnline = navigator.onLine;
  };

  public checkConnectivity(): boolean {
    return this.isOnline;
  }
}

export const internetConnectivity = InternetConnectivity.getInstance();
