/* PostalCodeComponent.tsx */

import React from 'react';
import { Grid } from '@mui/material';
import { PostalGridButton, FooterButton, PostalFooterView, PostCodeComponentContainer, PostCodeGridContainer } from '../../styles/StylesMatchingScreen';

interface PostalCodeComponentProps {
    gridAlignment: 'center' | 'left' | 'right',
    onClick: (item: string) => void;
    postalCodes: string[]
    onNoneOfTheAboveClick: () => void;
    footerText: string;
}

const PostalCodeComponent: React.FC<PostalCodeComponentProps> = (props: PostalCodeComponentProps) => {
    return (
        <PostCodeComponentContainer data-testid="PostCodeComponentContainer">
            <PostCodeGridContainer>
                <Grid data-testid="PostalCodeComponentOuterGrid"
                    container spacing={3}
                    marginTop={2}
                    justifyContent={props.gridAlignment}
                >
                    {props.postalCodes.map((postalCode, index) => {
                        return (
                            <Grid data-testid="PostalCodeComponentInnerGrid"
                                item key={`${index}.${postalCode}`}
                            >
                                <PostalGridButton data-testid="PostalGridButton"
                                    onClick={() => props.onClick(props.postalCodes[index])}
                                    variant="contained"
                                >
                                    {postalCode}
                                </PostalGridButton>
                            </Grid>
                        )
                    })}
                </Grid>
            </PostCodeGridContainer>
            <PostalFooterView data-testid="PostalCodeComponentFooter">
                <FooterButton data-testid="FooterButton"
                    onClick={props.onNoneOfTheAboveClick}
                >
                    {props.footerText}
                </FooterButton>
            </PostalFooterView>
        </PostCodeComponentContainer>
    );
};

export default PostalCodeComponent;
