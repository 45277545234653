import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import moment from "moment";
import { apiContainer } from "../api/APIContainer";
import { AppointmentSessionsParameters } from "../api/apiParameterModels/AppointmentSessionsParameters";
import { BookAppointmentParams } from "../api/apiParameterModels/BookAppointmentParams";
import { SessionSlotsParameters } from "../api/apiParameterModels/SessionSlotsParameters";
import { APIRepoKeys } from "../api/APIRepoKeys";
import { AppointmentSession, AppointmentSessionsResult, Holder } from "../api/apiResultModels/AppointmentSessionsResult";
import { SessionSlotsResult, Slot } from "../api/apiResultModels/SessionSlotsResult";
import { IAppointmentRepository } from "../api/interfaces/IAppointmentRepository";
import { ColorConstant } from "../constants/ColorConstant";
import { Strings } from "../constants/StringConstant";
import { SlotStatus } from "../enum";
import { addMinutesToTime, DateFormat, formatDate, getDateInISOString, getEndOfDayAfter21DaysFromNowInISOString, getFutureDateByAddingMonths, getFutureDateByAddingWeeks, getStartAndEndDateTime, isPastDate, isToday } from "../helpers/DateTimeHelper";
import { isEmpty, isEqualIgnoreCase, isNonEmpty } from "../helpers/StringHelper";
import { SlotUIEntity } from "../uiEntities/SlotUIEntity";
import { AppointmentsByDate } from "../view/web/screens/bookAppointment/BookAppointmentContainer";
import { LoginViewModel } from "./LoginViewModel";

const appointmentRepository = apiContainer.get<IAppointmentRepository>(
    APIRepoKeys.APPOINTMENT_API_REPOSITORY,
);

export interface AppointmentListItem {
    title: string;
    description?: string;
}

export interface CalendarComponent {
    date: Date;
    isTodayDate: boolean;
    isPastDate: boolean;
    hasAppointment: boolean;
}

export const BookAppointmentViewModel = () => {
    const getAppointments = (): AppointmentListItem[] => {
        const appointments = [
            { title: Strings.BookAppointment.InOneWeek, description: moment(getFutureDateByAddingWeeks(new Date(), 1)).format(DateFormat.BA_DateDescription) },
            { title: Strings.BookAppointment.InTwoWeeks, description: moment(getFutureDateByAddingWeeks(new Date(), 2)).format(DateFormat.BA_DateDescription) },
            { title: Strings.BookAppointment.InOneMonth, description: moment(getFutureDateByAddingMonths(new Date(), 1)).format(DateFormat.BA_DateDescription) },
        ]
        return appointments
    }

    const getAllDatesOfMonthFromGivenDate = (givenDate: Date): (CalendarComponent)[] => {
        const year = givenDate.getFullYear();
        const month = givenDate.getMonth();
        const dates: CalendarComponent[] = [];

        let date = new Date(year, month, 1);
        let index = 0
        while (date.getMonth() === month) {
            const _date = new Date(date)
            dates.push({ date: _date, isTodayDate: isToday(_date), hasAppointment: false, isPastDate: isPastDate(_date) });
            date.setDate(date.getDate() + 1);
            index = index + 1
        }
        return dates;
    }

    const getWeekDatesFromDate = (date: Date): CalendarComponent[] => {
        const currentDate = new Date(date);
        const dayOfWeek = currentDate.getDay();

        // Get the Monday of the current week
        const monday = new Date(currentDate);
        monday.setDate(currentDate.getDate() - (dayOfWeek + 6) % 7);

        // Get all the dates from Monday to Sunday
        const dates: CalendarComponent[] = [];
        for (let index = 0; index < 7; index++) {
            const weekDate = new Date(monday);
            weekDate.setDate(monday.getDate() + index);
            dates.push({ date: weekDate, isTodayDate: isToday(weekDate), isPastDate: isPastDate(weekDate), hasAppointment: false });
        }
        return dates;
    }

    function getAppointmentSessionsParametersForWeekSelection(dates: CalendarComponent[], slotTypeId: string) {
        const filteredDates = dates.filter(date => date.isPastDate !== true)
        if (filteredDates.length > 0) {
            const startDateEndTime = getStartAndEndDateTime(filteredDates[0].date)
            const endDateEndTime = getStartAndEndDateTime(filteredDates[filteredDates.length - 1].date)
            const params: AppointmentSessionsParameters = {
                slotType: slotTypeId,
                startDate: startDateEndTime.startDateTime,
                endDate: endDateEndTime.endDateTime,
            };
            return params
        }
        return undefined
    }

    const generateRandomSlotTimings = (fromTime: number, toTime: number, interval: number): Date[] => {
        const timings = [];
        const endTime = toTime <= 24 && fromTime < toTime ? toTime : 24
        const startTime = new Date();
        startTime.setHours(fromTime, 0, 0, 0); // Set the start time to 9:00 AM

        while (startTime.getHours() < endTime) { // Loop until the hour is less than 5 PM
            timings.push(new Date(startTime));
            startTime.setMinutes(startTime.getMinutes() + interval); // Increment the time by 15 minutes
        }
        console.log('timings => ', timings)
        return timings;
    };

    async function getNextAvailableSessionSlot(dispatch: Dispatch<UnknownAction>, selectedSloType: string): Promise<SlotUIEntity | undefined> {
        try {
            const isValidSession = await LoginViewModel().validateSession(dispatch)
            if (isValidSession) {
                const sessionParam: AppointmentSessionsParameters = {
                    slotType: selectedSloType,
                    startDate: getDateInISOString(new Date()),
                    endDate: getEndOfDayAfter21DaysFromNowInISOString(),
                }
                const slots = await getNextAvailableSessionSlots(sessionParam)
                const sortedSlots = sortSlotOnStartTime(slots)
                const nextAvailableSlot = sortedSlots.length === 0 ? undefined : sortedSlots[0]
                return Promise.resolve(nextAvailableSlot);
            } else {
                return Promise.resolve(undefined);
            }
        }
        catch (error) {
            if (error instanceof Error) {
                return Promise.reject(error);
            } else {
                return Promise.reject(new Error('An unknown error occurred'));
            }
        }
    }

    async function getNextAvailableSessionSlots(sessionParam: AppointmentSessionsParameters): Promise<SlotUIEntity[]> {
        try {
            const appointmentSessions = await handleAppointmentSessionAPI(sessionParam);
            const filteredSessions = filterSessionWithAvailableSlots(appointmentSessions);
            if (filteredSessions.length === 0) {
                return Promise.resolve([]);
            } else {
                const sortedSessions = sortSessionOnStartDateTime(filteredSessions);
                const nextAvailableSessionSlots = await handleSessionSlotsAPI(sortedSessions[0]);
                const nextAvailableSessionAvailableSlots = nextAvailableSessionSlots.filter(slot => isEqualIgnoreCase(slot.status, SlotStatus.SlotAvailable));
                return Promise.resolve(nextAvailableSessionAvailableSlots)
            }
        }
        catch (error) {
            if (error instanceof Error) {
                return Promise.reject(error);
            } else {
                return Promise.reject(new Error('An unknown error occurred'));
            }
        }
    }

    function handleAppointmentSessionAPI(params: AppointmentSessionsParameters): Promise<AppointmentSession[]> {
        return new Promise((resolve, reject) => {
            appointmentRepository.getAppointmentSessions(params)
                .then((responseString) => {
                    const appointmentSessionsResult: AppointmentSessionsResult = JSON.parse(responseString);
                    resolve(appointmentSessionsResult.appointmentSession ?? [])
                })
                .catch((error: Error) => {
                    reject(error)
                })
        })
    }

    async function handleSessionSlotsAPI(session: AppointmentSession): Promise<SlotUIEntity[]> {
        try {
            const sessionID = session.dbid ? `${session.dbid}` : ''
            if (isEmpty(sessionID)) {
                return Promise.resolve([])
            }
            const params: SessionSlotsParameters = { appointmentSessionId: sessionID }
            const result = await appointmentRepository.getSessionSlots(params)
            const sessionSlotsResult: SessionSlotsResult = JSON.parse(result)
            if (sessionSlotsResult.slots) {
                const entities = mapToSlotUIEntities(session, sessionSlotsResult.slots)
                return Promise.resolve(entities)
            } else {
                return Promise.resolve([])
            }
        }
        catch (error) {
            if (error instanceof Error) {
                return Promise.reject(error);
            } else {
                return Promise.reject(new Error('An unknown error occurred'));
            }
        }
    }

    async function handleAllSlotsForTheSessions(sessionIDs: number[]): Promise<SessionSlotsResult[]> {
        let promises: Promise<string>[] = []
        for (const sessionID of sessionIDs) {
            if (sessionID !== undefined) {
                const params: SessionSlotsParameters = { appointmentSessionId: `${sessionID}` }
                promises.push(appointmentRepository.getSessionSlots(params))
            }
        }
        try {
            const allResult = await Promise.all(promises)
            const result: SessionSlotsResult[] = allResult.map(result => JSON.parse(result))
            return Promise.resolve(result)
        }
        catch (error) {
            if (error instanceof Error) {
                return Promise.reject(error);
            } else {
                return Promise.reject(new Error('An unknown error occurred'));
            }
        }
    }

    async function handleBookAppointment(params: BookAppointmentParams): Promise<boolean> {
        try {
            const result = await appointmentRepository.getAppointmentSlot(params)
            if (result) {
                return Promise.resolve(result)
            } else {
                return false
            }
        }
        catch (error) {
            if (error instanceof Error) {
                return Promise.reject(error);
            } else {
                return Promise.reject(new Error('An unknown error occurred'));
            }
        }
    }

    function mapToSlotUIEntities(session: AppointmentSession, slots: Slot[]) {
        let slotUIEntities: SlotUIEntity[] = []
        for (const slot of slots) {
            const slotUIEntity: SlotUIEntity = {
                slotId: slot.slotId,
                locationDisplayName: session.site?.name ?? "",
                startDateTime: slot.startTime ?? '',
                slotLength: slot.slotLength ?? '',
                endDateTime: slot.startTime && slot.slotLength ? addMinutesToTime(slot.startTime, slot.slotLength) : "",
                sessionId: session.dbid,
                sessionHolderName: (session.holderList?.holder && session.holderList.holder.length > 0) ? formatSessionHolderName(session.holderList.holder[0]) : '',
                slotTypeDescription: (session.slotTypeList?.slotType && session.slotTypeList.slotType.length > 0) ? session.slotTypeList.slotType[0].description : '',
                date: session.date,
                status: slot.status,
            }
            slotUIEntities.push(slotUIEntity)
        }
        return slotUIEntities;
    }

    function formatSessionHolderName(holder: Holder | undefined) {
        let formattedName = ''
        if (holder?.title && isNonEmpty(holder?.title)) {
            formattedName = `${formattedName} ${holder.title}`
        }
        if (holder?.firstNames && isNonEmpty(holder?.firstNames)) {
            formattedName = `${formattedName} ${holder.firstNames}`
        }
        if (holder?.lastName && isNonEmpty(holder?.lastName)) {
            formattedName = `${formattedName} ${holder.lastName}`
        }
        return formattedName
    }

    function filterSessionWithAvailableSlots(sessions: AppointmentSession[]) {
        return sessions.filter(session => session.slotTypeList?.slotType?.some(slotType => (slotType?.available ?? 0) > 0))
    }

    function sortSessionOnStartDateTime(sessions: AppointmentSession[]) {
        const sortedSessions = [...sessions].sort((sessionA: AppointmentSession, sessionB: AppointmentSession) => {
            const sessionADateTime = sessionA.date && sessionA.startTime ? new Date(`${sessionA.date.split('/').reverse().join('-')}T${sessionA.startTime}`) : new Date(0);
            const sessionBDateTime = sessionB.date && sessionB.startTime ? new Date(`${sessionB.date.split('/').reverse().join('-')}T${sessionB.startTime}`) : new Date(0);
            return sessionADateTime.getTime() - sessionBDateTime.getTime();
        })
        return sortedSessions
    }

    function sortSlotOnStartTime(slots: SlotUIEntity[]) {
        const sortedSlots = [...slots].sort((a, b) => {
            if (!a.startDateTime) return 1;
            if (!b.startDateTime) return -1;
            return a.startDateTime.localeCompare(b.startDateTime);
        });
        return sortedSlots
    }

    const getCalendarButtonStyle = (hasAppointment: boolean, isTodayDate: boolean) => {
        return {
            backgroundColor: hasAppointment ? ColorConstant.CERAMIC_BLUE_TURQUOISE : ColorConstant.DESIRED_DAWN,
            border: isTodayDate ? `5px solid ${ColorConstant.MICA_CREEK}` : 'none'
        }
    };

    function getCalendarComponent(calendarComponent: CalendarComponent[], sessionsByDate: AppointmentsByDate) {
        let updatedComponent = [...calendarComponent]
        for (let index = 0; index < calendarComponent.length; index++) {
            const component = calendarComponent[index]
            if (component) {
                let date = formatDate(component.date, DateFormat.DDMMYY_Slash)
                const sessions = sessionsByDate[date]
                if (sessions) {
                    const isAvailable = filterSessionWithAvailableSlots(sessions).length > 0
                    updatedComponent[index] = { ...component, hasAppointment: isAvailable }
                }
            }
        }
        return updatedComponent
    };

    function convertSessionsByDate(sessions: AppointmentSession[]) {
        const appointmentsByDate: AppointmentsByDate = sessions.reduce((source, session) => {
            if (session.date) {
                if (!source[session.date]) {
                    source[session.date] = [];
                }
                source[session.date].push(session);
            }
            return source;
        }, {} as AppointmentsByDate);
        return appointmentsByDate
    }

    function getSessionHolders(sessions: AppointmentSession[]): (Holder | undefined)[] {
        return sessions.flatMap(session => session.holderList?.holder).filter(holder => holder !== undefined)
    }

    function getSessionHolderNamesFromSessions(sessions: AppointmentSession[]): string[] {
        const sessionHolders = sessions.flatMap(session => session?.holderList?.holder).filter(holder => holder !== undefined)
        const sessionHolderNames = sessionHolders.map(sessionHolder => formatSessionHolderName(sessionHolder))
        console.log(sessionHolderNames)
        return sessionHolderNames
    }

    return {
        getAppointments,
        getAllDatesOfMonthFromGivenDate,
        getWeekDatesFromDate,
        generateRandomSlotTimings,
        getNextAvailableSessionSlot,
        handleAppointmentSessionAPI,
        handleBookAppointment,
        getCalendarButtonStyle,
        getCalendarComponent,
        convertSessionsByDate,
        getSessionHolders,
        getSessionHolderNamesFromSessions,
        formatSessionHolderName,
        handleAllSlotsForTheSessions,
        sortSessionOnStartDateTime,
        mapToSlotUIEntities,
        getAppointmentSessionsParametersForWeekSelection,
        filterSessionWithAvailableSlots,
    }
}
