
// ValidationHelper.ts

import { padDigitsInSuffix } from "./CommonHelper";
import moment from "moment";

export const isValidDate = (day: string, month: string, year: string): boolean => {
    const isAllDateComponentsAreValid = isValidDay(day, month, year) && isValidMonth(month) && isValidYear(year) && year.length === 4;
    if(isAllDateComponentsAreValid) {
        return validateDOB(`${day}.${month}.${year}`)
    }
    return isAllDateComponentsAreValid
};

export const isValidDayOnly = (day: string): boolean => {
    if(day.length > 3) {
        return false
    }
    const paddedDay = day.length === 2 ? day : padDigitsInSuffix(day, 2)
    const dayInt = parseInt(paddedDay, 10);
    if(isNaN(dayInt) || dayInt > 31) {
        return false
    }
    return true
}

export const isValidDay = (day: string, month: string, year: string): boolean => {
    // Convert to integers
    const dayInt = parseInt(day, 10);
    const monthInt = parseInt(month, 10);
    const yearInt = parseInt(year, 10);

    if(isNaN(dayInt) || isNaN(monthInt) || isNaN(yearInt)) {
        return false
    }

    // Check if day is valid based on month and leap year
    const daysInMonth = [31, (isLeapYear(yearInt) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (dayInt < 1 || dayInt > daysInMonth[monthInt - 1]) {
        return false;
    }

    return true;
}

export const isValidMonth = (month: string): boolean => {
    // Convert to integers
    const monthInt = parseInt(month, 10);

    // Check if month is valid
    if (monthInt < 1 || monthInt > 12) {
        return false;
    }

    return true;
}

export const isValidYear = (year: string): boolean => {

    if(year.length > 4) {
        return false
    }

    const paddedYear = year.length === 4 ? year : padDigitsInSuffix(year, 4)
    const yearInt = parseInt(paddedYear, 10);

    // Current year
    const currentYear = new Date().getFullYear();

    // Check if year is within the valid range
    return yearInt <= currentYear
}

// Function to check if a year is a leap year
const isLeapYear = (year: number): boolean => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
};

export const validateDOB = (date: string): boolean => {
    const today = new Date();
    const inputDate = moment(date, 'DD.MM.YYYY').toDate();
    return inputDate && inputDate < today 
}