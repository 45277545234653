/* CalendarDateItem.tsx */

import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { CalendarComponent } from '../../../../../viewModels/BookAppointmentViewModel';
import { formatDate } from '../../../../../helpers/DateTimeHelper';
import { ColorConstant } from '../../../../../constants/ColorConstant';
import { DayGridItemButton, DateStripDayText, DateStripDateMonthText, CalendarDateItemContainer } from '../../../styles/StylesBookAppointment';
interface CalendarDateItemProps {
    dates: CalendarComponent[],
    selectedDate?: Date;
    onDateClick: (date: Date) => void;
}

interface DayGridItemProps {
    dateComponent: CalendarComponent;
    onClick: () => void;
    isSmallDimension: boolean;
    selectedDate?: Date;
}

const DayGridItem: React.FC<DayGridItemProps> = (props: DayGridItemProps) => {
    const dateSplits = formatDate(props.dateComponent.date, 'ddd,DD MMM').split(",")
    const isSelectedDate = props.selectedDate?.getDate() === props.dateComponent.date.getDate()
    let textColor: string = props.dateComponent.hasAppointment ? ColorConstant.EMERALD_STONE : ColorConstant.UNIQUE_GREY
    textColor = isSelectedDate ? ColorConstant.WHITE : textColor

    return (
        <DayGridItemButton isSelectedDate={isSelectedDate} isSmallDimension={props.isSmallDimension} sx={{ width: props.isSmallDimension ? 50 : 90 }}
            disabled={!props.dateComponent.hasAppointment}
            onClick={() => {
                props.onClick()
            }}
        >
            {dateSplits.length > 0 ? <DateStripDayText textColor={textColor} isSmallDimension={props.isSmallDimension}>{dateSplits[0]}</DateStripDayText> : null}
            {dateSplits.length > 1 ? <DateStripDateMonthText textColor={textColor} isSmallDimension={props.isSmallDimension}>{dateSplits[1]}</DateStripDateMonthText> : null}
        </DayGridItemButton>
    );
};

const CalendarDateItem: React.FC<CalendarDateItemProps> = (props: CalendarDateItemProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const isSmallDimension = isMobile || isPortrait

    return (
        <CalendarDateItemContainer isSmallDimension={isSmallDimension}>
            {props.dates.map((date, index) => (
                <DayGridItem
                    key={`${index.toString()}. ${date}`}
                    onClick={() => props.onDateClick(props.dates[index].date)}
                    dateComponent={date}
                    selectedDate={props.selectedDate}
                    isSmallDimension={isSmallDimension}
                />
            ))}
        </CalendarDateItemContainer>
    );
};

export default CalendarDateItem;