/* MessageBanner.tsx */

import { isNonEmpty } from "../../../helpers/StringHelper";
import { MessageBannerText, MessageBannerTitleText, MessageBannerView } from "../styles/StylesCommonView";


interface MessageBannerProps {
    message: string
    title?: string
}

const MessageBanner: React.FC<MessageBannerProps> = (props: MessageBannerProps) => {
    return (
        <MessageBannerView>
            <MessageBannerText>
                {props.message}
            </MessageBannerText>
            {isNonEmpty(props.title) ?
                <MessageBannerTitleText>
                    {props.title}
                </MessageBannerTitleText>
                : null
            }
        </MessageBannerView>

    );
};

export default MessageBanner;