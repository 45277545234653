/* KeyboardComponent.tsx */

import React from 'react';
import deleteLeftIcon from '../../../../../images/delete.png'
import { ColorConstant } from '../../../../../constants/ColorConstant';
import { KeyboardContainer, KeyboardGridView, KeyboardGridButton, KeyboardGridEmptyView, KeyboardDeleteButton, KeyboardDeleteIcon, KeyboardNextButton } from '../../../styles/StylesMatchingScreen';
interface KeyboardComponentProps {
  onNumberClick: (number: number) => void;
  onDeleteClick: () => void;
  onNextClick: () => void;
  enableDeleteButton: boolean;
  enableNextButton: boolean;
  nextText: string;
  nextTextColor: string;
}

const KeyboardComponent: React.FC<KeyboardComponentProps> = (props: KeyboardComponentProps) => {
  return (
    <KeyboardContainer>
      <KeyboardGridView>
        <KeyboardGridButton onClick={() => props.onNumberClick(1)}>1</KeyboardGridButton>
        <KeyboardGridButton onClick={() => props.onNumberClick(2)}>2</KeyboardGridButton>
        <KeyboardGridButton onClick={() => props.onNumberClick(3)}>3</KeyboardGridButton>
        <KeyboardGridButton onClick={() => props.onNumberClick(4)}>4</KeyboardGridButton>
        <KeyboardGridButton onClick={() => props.onNumberClick(5)}>5</KeyboardGridButton>
        <KeyboardGridButton onClick={() => props.onNumberClick(6)}>6</KeyboardGridButton>
        <KeyboardGridButton onClick={() => props.onNumberClick(7)}>7</KeyboardGridButton>
        <KeyboardGridButton onClick={() => props.onNumberClick(8)}>8</KeyboardGridButton>
        <KeyboardGridButton onClick={() => props.onNumberClick(9)}>9</KeyboardGridButton>
        <KeyboardGridEmptyView/>
        <KeyboardGridButton onClick={() => props.onNumberClick(0)}>0</KeyboardGridButton>
        <KeyboardDeleteButton data-testid="backIconView"
          disabled={!props.enableDeleteButton}
          style={{
            opacity: props.enableDeleteButton ? 1 : 0.2,
            cursor: props.enableDeleteButton ? 'pointer' : 'not-allowed'
          }}
          onClick={() => props.onDeleteClick()}>
          <KeyboardDeleteIcon src={deleteLeftIcon} alt="back" />
        </KeyboardDeleteButton>
      </KeyboardGridView>
      <KeyboardNextButton
        onClick={props.onNextClick}
        style={{
          backgroundColor: props.enableNextButton ? ColorConstant.CERAMIC_BLUE_TURQUOISE : ColorConstant.GREY,
          color: props.nextTextColor,
          cursor: props.enableNextButton ? 'pointer' : 'not-allowed'
        }}
      >
        {props.nextText}
      </KeyboardNextButton>
    </KeyboardContainer>
  );
};

export default KeyboardComponent;
