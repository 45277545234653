

export enum DD_APIEndPointName {
    Token_API_Endpoint = 'Get Token',
    PatientSearch_API_Endpoint='Patient Search',
    BookedPatient_API_Endpoint='Get Booked Patient',
    SetAppointmentStatus_API_Endpoint='Set Appointment Status',
    GetAppointmentSessions_API_Endpoint='Get Appointment Sessions',
    GetSlotsForSession_API_Endpoint='Get Session Slots',
    BookAppointment_API_Endpoint='BookAppointment',
}