import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TokenResult } from '../../api/apiResultModels/TokenResult';
import { DateFormat, getTodayDateTimeString } from '../../helpers/DateTimeHelper';

export type TokenState = {
  accessToken: string;
  tokenCreatedDateTime: string,
  tokenExpiresIn?: number
};
const initialState: TokenState = {
  accessToken: '',
  tokenCreatedDateTime: '',
  tokenExpiresIn: undefined,
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState: initialState,
  reducers: {
    setTokenDetails: (state, action: PayloadAction<TokenResult>) => {
      state.accessToken = action.payload.access_token ?? '';
      state.tokenExpiresIn = action.payload.expires_in;
      state.tokenCreatedDateTime = getTodayDateTimeString(DateFormat.FullDateTime);
    },
  },
});

export default tokenSlice.reducer;
