import { useTranslation } from "react-i18next"
import { Strings } from "../../../../../constants/StringConstant"
import { AppointmentUIEntity } from "../../../../../viewModels/CheckInViewModel"
import { SingleAppointmentContainer, SingleAppointmentText, SingleAppointmentTimeText, SingleAppointmentWithText, SingleAppointmentSessionHolderNameText, AppointmentListContentView, AppointmentListSelectAppointmentText, SelectAppointmentListView } from "../../../styles/StylesCheckIn"
import { AppointmentItem } from "../listItem/AppointmentItem"

interface AppointmentListViewProps {
    showAppointmentTitle: boolean,
    appointments: AppointmentUIEntity[]
    earlyArrival: number,
    selectedAppointments: AppointmentUIEntity[],
    setSelectedAppointments: React.Dispatch<React.SetStateAction<AppointmentUIEntity[]>>,
}

const AppointmentListView: React.FC<AppointmentListViewProps> = (props: AppointmentListViewProps) => {
    const isShowSingleAppointment = props.appointments.length === 1 && props.selectedAppointments.length > 0 && !props.selectedAppointments[0].isEarlyAppointment && !props.selectedAppointments[0].isLateAppointment
    const { t } = useTranslation();
    if (isShowSingleAppointment) {
        const appointment = props.appointments[0]
        return (
            <SingleAppointmentContainer>
                <SingleAppointmentText>Appointment:</SingleAppointmentText>
                <SingleAppointmentTimeText>{appointment.appointmentTime}</SingleAppointmentTimeText>
                <SingleAppointmentWithText> with </SingleAppointmentWithText>
                <SingleAppointmentSessionHolderNameText>{appointment.sessionHolderName}</SingleAppointmentSessionHolderNameText>
            </SingleAppointmentContainer>
        )
    } else {
        return (
            <AppointmentListContentView>
                {props.showAppointmentTitle ?
                    <AppointmentListSelectAppointmentText>
                        {t(Strings.CheckIn.SelectAppointments)}
                    </AppointmentListSelectAppointmentText>
                    : null
                }
                <SelectAppointmentListView>
                    {props.appointments.map((appointment, index) => (
                        <AppointmentItem
                            key={`${index.toString()}. ${appointment.appointmentId}`}
                            appointment={appointment}
                            earlyArrival={props.earlyArrival}
                            isSelected={props.selectedAppointments.find(selectedAppointment => selectedAppointment.appointmentId === appointment.appointmentId) !== undefined}
                            handleAppointmentSelection={() => {
                                const index = props.selectedAppointments.findIndex(selectedAppointment => selectedAppointment.appointmentId === appointment.appointmentId)
                                let selectedAppointments = [...props.selectedAppointments]
                                if (index === -1) {
                                    selectedAppointments = [...selectedAppointments, appointment]
                                } else {
                                    selectedAppointments = [...selectedAppointments.slice(0, index), ...selectedAppointments.slice(index + 1)]
                                }
                                props.setSelectedAppointments(selectedAppointments)
                            }}
                        />
                    ))}
                </SelectAppointmentListView>
            </AppointmentListContentView>
        )
    }
}

export default AppointmentListView