/* CheckInContainer.tsx */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BookedPatientResult, Patient } from '../../../../api/apiResultModels/BookedPatientResult';
import { PatientFindResult } from '../../../../api/apiResultModels/PatientFindResult';
import { Strings } from '../../../../constants/StringConstant';
import { resetToDefaultLanguage } from '../../../../helpers/CommonHelper';
import { isNonEmpty } from '../../../../helpers/StringHelper';
import { RouterName } from '../../../../navigation/RouterName';
import { setAPICallInProgress } from '../../../../redux/actions/GlobalAction';
import { RootState, store, useAppDispatch } from '../../../../redux/Store';
import { AppConfig } from '../../../../utils/AppConfig';
import { AppConstants } from '../../../../utils/constants/AppConstants';
import { internetConnectivity } from '../../../../utils/InternetConnectivity';
import { AppointmentUIEntity, CheckInViewModel } from '../../../../viewModels/CheckInViewModel';
import { LoginViewModel } from '../../../../viewModels/LoginViewModel';
import LanguagePopupScreen from '../../commonViews/LanguagePopupScreen';
import Loader from '../../commonViews/Loader';
import MessageBanner from '../../commonViews/MessageBanner';
import SomethingWentWrongScreen from '../../commonViews/SomethingWentWrongScreen';
import TopBar from '../../commonViews/TopBar';
import { CheckInContainerScrollableView, CheckInContainerTopView, CheckInContainerView } from '../../styles/StylesCheckIn';
import MatchingContainerScreen, { MatchingComponents } from '../matchingScreen/MatchingContainerScreen';
import NoMatchingScreen from '../matchingScreen/NoMatchingScreen';
import AppointmentListScreen from './appointmentListScreen/AppointmentListScreen';
import CheckInSuccessScreen from './CheckInSuccessScreen';
import PatientDemographicScreen from './patientInfoScreen/PatientDemographicScreen';

const CheckInContainer: React.FC = () => {
    const [matchingComponents, setMatchingComponents] = React.useState<MatchingComponents>({ date: '', day: '', month: '', year: '', gender: '', surNameFirstLetter: '', postalCode: '' })
    const [showMatchingScreen, setShowMatchingScreen] = React.useState(true);
    const [showPatientInfoScreen, setShowPatientInfoScreen] = React.useState(false);
    const [showNoMatchingScreen, setShowNoMatchingScreen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState<number>(0)
    const [showAppointmentListScreen, setShowAppointmentListScreen] = React.useState<boolean>(false)
    const [showAppointmentSuccessScreen, setShowAppointmentSuccessScreen] = React.useState<boolean>(false)
    const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false)
    const matchTitles = store.getState().configSlice.patientMatchTitles
    const navigate = useNavigate();
    const [showPostCode, setShowPostCode] = React.useState<boolean>(false)
    const [postCodes, setPostCodes] = React.useState<string[]>([])
    const [selectedPatient, setSelectedPatient] = React.useState<Patient | undefined>(undefined)
    const [selectedAppointments, setSelectedAppointments] = React.useState<AppointmentUIEntity[]>([])
    const [appointments, setAppointments] = React.useState<AppointmentUIEntity[]>([])
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const isOnline = internetConnectivity.checkConnectivity();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    function handleBackAction() {
        if (showPostCode) {
            setShowPostCode(false);
        } else if (showPatientInfoScreen) {
            setShowPatientInfoScreen(false);
            setShowMatchingScreen(true);
        } else if (currentIndex === 0) {
            if (showAppointmentListScreen) {
                setCurrentIndex(matchTitles.length - 1);
                setShowMatchingScreen(true);
                setShowAppointmentListScreen(false);
            } else {
                navigate(RouterName.Home);
            }
        } else if (currentIndex === matchTitles.length - 1 && showAppointmentListScreen) {
            setShowAppointmentListScreen(false);
            setShowMatchingScreen(true);
        } else {
            setCurrentIndex(preValue => preValue > 0 ? preValue - 1 : preValue);
        }
    }

    function initializeStateVariables() {
        setMatchingComponents({ date: '', day: '', month: '', year: '', gender: '', surNameFirstLetter: '', postalCode: '' });
        setShowMatchingScreen(false);
        setShowNoMatchingScreen(false);
        setShowAppointmentListScreen(false);
        setShowAppointmentSuccessScreen(false);
        setShowSomethingWentWrongScreen(false)
        setShowPatientInfoScreen(false);
        setShowPostCode(false);
        setCurrentIndex(0);
    }

    async function handlePatientAPI(matchingComponents: MatchingComponents) {
        if (!isOnline) {
            return;
        }
        dispatch(setAPICallInProgress(true));
        LoginViewModel().validateSession(dispatch)
            .then((isValidSession) => {
                if (isValidSession) {
                    CheckInViewModel().initiatePatientFindAPI(matchingComponents)
                        .then(async (patientFindResult) => {
                            await handlePatientFindResult(patientFindResult, matchingComponents)
                        })
                        .catch((error: Error) => {
                            handleAPIError(error)
                        })
                } else {
                    handleAPIError()
                }
            })
            .catch((error: Error) => {
                handleAPIError(error)
            })
    }

    async function handlePatientFindResult(patientFindResult: PatientFindResult, matchingComponents: MatchingComponents) {
        if (patientFindResult?.patients && patientFindResult.patients.length === 1 && isNonEmpty(patientFindResult.patients[0].patientId)) {
            await handleBookedPatientsAPI(patientFindResult.patients[0], matchingComponents)
        } else if (patientFindResult?.patients && patientFindResult.patients.length > 1) {
            handleMultiplePatientFromSearchResult(patientFindResult.patients, matchingComponents)
        } else {
            handleNotYouAction(matchingComponents);
        }
    }

    async function handleBookedPatientsAPI(patient: Patient, matchingComponents: MatchingComponents) {
        if (!isOnline) {
            dispatch(setAPICallInProgress(false))
            return;
        }
        if (patient) {
            CheckInViewModel().initiateBookedPatientsAPI()
                .then((bookedPatientResult) => {
                    handleBookedPatientResult(patient, bookedPatientResult, matchingComponents)
                })
                .catch((error: Error) => {
                    handleAPIError(error)
                })
        } else {
            handleNotYouAction(matchingComponents);
        }
    }

    function handleMultiplePatientFromSearchResult(patients: Patient[], matchingComponents: MatchingComponents) {
        CheckInViewModel().initiateBookedPatientsAPI()
            .then((bookedPatientResult) => {
                const patientIDsFromPatientSearch = patients.map(patient => patient.patientId).filter(patientID => patientID !== undefined)
                const uniquePatientIDsFromPatientSearch = Array.from(new Set(patientIDsFromPatientSearch))
                const patientIDsFromBookedPatientResult = bookedPatientResult.bookedList?.booked?.map(booked => booked.patient?.patientId).filter(patientID => patientID !== undefined) ?? []
                const uniquePatientIDsFromBookedPatientResult = Array.from(new Set(patientIDsFromBookedPatientResult))
                const uniquePatientIDs = uniquePatientIDsFromBookedPatientResult.filter(name => uniquePatientIDsFromPatientSearch.includes(name));
                dispatch(setAPICallInProgress(false))
                if (uniquePatientIDs.length === 1) {
                    const patient = patients.find(patient => patient.patientId === uniquePatientIDs[0])
                    if (patient) {
                        handleBookedPatientResult(patient, bookedPatientResult, matchingComponents)
                    }
                } else if (uniquePatientIDs.length === 0 || isNonEmpty(matchingComponents.postalCode)) {
                    handleNotYouAction(matchingComponents);
                } else {
                    const postCodes = CheckInViewModel().getUniqueNonEmptyPostCodesFromPatients(patients);
                    const updatedPostCodes = CheckInViewModel().addRandomPostCodesIfNeeded(postCodes, AppConstants.MaximumPostCodes);
                    setPostCodes(updatedPostCodes);
                    setShowPostCode(true);
                }
            })
            .catch((error: Error) => {
                handleAPIError(error)
            })
    }

    function getAutoConfirmArrival() {
        return store.getState().configSlice.allConfig?.AutoConfirmArrival ?? AppConfig.defaultValues.AutoConfirmArrival
    }

    function getAutoConfirmMultipleArrival() {
        return store.getState().configSlice.allConfig?.AutoConfirmMultipleArrival ?? AppConfig.defaultValues.AutoConfirmMultipleArrival
    }

    function handleBookedPatientResult(patient: Patient, bookedPatientResult: BookedPatientResult, matchingComponents: MatchingComponents) {
        dispatch(setAPICallInProgress(false))
        if (isNonEmpty(patient.patientId)) {
            const bookings = bookedPatientResult.bookedList?.booked?.filter(booked => booked.patient?.patientId === patient.patientId) ?? [];
            const bookedPatient = bookedPatientResult.bookedList?.booked?.find(booked => booked.patient?.patientId === patient.patientId)?.patient
            const autoConfirmArrival = getAutoConfirmArrival()
            const autoConfirmMultipleArrival = getAutoConfirmMultipleArrival()
            if (bookings.length > 0 && bookedPatient) {
                setSelectedPatient(bookedPatient);
                const appointmentUIEntities: AppointmentUIEntity[] = CheckInViewModel().mapToAppointmentUIEntity(bookings, bookedPatientResult.sessionHolderList?.sessionHolder ?? []);
                const availableAppointments = appointmentUIEntities.filter(appointment => !appointment.isEarlyAppointment && !appointment.isLateAppointment)
                const allowAutoCheckIn = (autoConfirmArrival && appointmentUIEntities.length === 1 && availableAppointments.length === 1) || (autoConfirmMultipleArrival && appointmentUIEntities.length === availableAppointments.length)
                if (allowAutoCheckIn) {
                    handleCheckInAPI(appointmentUIEntities)
                } else {
                    setAppointments(appointmentUIEntities);
                    setShowMatchingScreen(false);
                    setShowAppointmentListScreen(true);
                }
            } else {
                handleNotYouAction(matchingComponents);
            }
        } else {
            handleNotYouAction(matchingComponents);
        }
    }

    async function handleCheckInAPI(selectedAppointments: AppointmentUIEntity[]): Promise<void> {
        if (!isOnline) {
            return;
        }
        dispatch(setAPICallInProgress(true))
        const selectedAppointmentIDs = selectedAppointments.map(appointment => appointment.appointmentId)
        CheckInViewModel().initiateCheckInAPI(selectedAppointmentIDs)
            .then((appointmentStatusResult) => {
                let appointments: AppointmentUIEntity[] = []
                appointmentStatusResult.forEach((result, index) => {
                    if(result.outcome === '1') {
                        appointments.push(selectedAppointments[index])
                    }
                })
                handleAppointmentStatusResult(appointments)
            })
            .catch((error: Error) => {
                handleAPIError(error)
            })
    }

    function handleAppointmentStatusResult(appointments: AppointmentUIEntity[]) {
        dispatch(setAPICallInProgress(false));
        const showDemographicDetails: boolean = store.getState().configSlice.allConfig?.ShowDemographicDetails ?? false
        setSelectedAppointments(appointments);
        setShowMatchingScreen(false);
        if(showDemographicDetails) {
            setShowAppointmentListScreen(false);
            setShowPatientInfoScreen(true);
        } else {
            setShowAppointmentListScreen(false);
            setShowAppointmentSuccessScreen(true);
        }
    }

    async function onCompletionCheckInPreRequisite(matchingComponents: MatchingComponents): Promise<void> {
        setMatchingComponents(matchingComponents);
        handlePatientAPI(matchingComponents);
    }

    function handleNotYouAction(matchingComponents: MatchingComponents): void {
        dispatch(setAPICallInProgress(false))
        initializeStateVariables();
        setMatchingComponents(matchingComponents);
        setShowNoMatchingScreen(true);
    }

    function handleAPIError(error?: Error) {
        console.log('error -> ', JSON.stringify(error))
        dispatch(setAPICallInProgress(false))
        initializeStateVariables();
        setShowSomethingWentWrongScreen(true)
    }

    function handleHomeClick() {
        const languages = store.getState().configSlice.allConfig?.languages ?? []
        resetToDefaultLanguage(dispatch, languages)
        navigate('/');
    }

    function handlePostalCodeSelection(matchingComponents: MatchingComponents) {
        setMatchingComponents(matchingComponents);
        handlePatientAPI(matchingComponents)
    }

    function tryAgainAction() {
        initializeStateVariables();
        setShowMatchingScreen(true);
    }

    return (
        <CheckInContainerView>
            <CheckInContainerTopView>
                <TopBar
                    isShowHomeButton={true}
                    isShowBackOption={currentIndex >= 0 && !showAppointmentSuccessScreen && !showNoMatchingScreen && !showPatientInfoScreen && !showSomethingWentWrongScreen}
                    handleBackClick={handleBackAction}
                />
                {showMatchingScreen && !showNoMatchingScreen ? <MessageBanner message={t(Strings.WeAreAskingTheseQuestionsToHelpIdentifyYou)} /> : null}
            </CheckInContainerTopView>
            {showMatchingScreen ?
                <CheckInContainerScrollableView>
                    <MatchingContainerScreen
                        matchingComponents={matchingComponents}
                        matchTitles={matchTitles}
                        showPostalCodes={showPostCode}
                        postalCodes={postCodes}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        onCompletionMatchingContainer={onCompletionCheckInPreRequisite}
                        onClickPostCode={handlePostalCodeSelection}
                        onNoneOfTheAboveClickOnPostCode={(matchingComponents) => {
                            setMatchingComponents(matchingComponents)
                            handleNotYouAction(matchingComponents)
                        }}
                    />
                </CheckInContainerScrollableView>
                : null
            }
            {showAppointmentListScreen ?
                <AppointmentListScreen
                    patientName={CheckInViewModel().formatPatientName(selectedPatient)}
                    appointments={appointments}
                    handleNotYouAction={() => {
                        handleNotYouAction(matchingComponents)
                    }}
                    handleCloseAction={handleHomeClick}
                    handleCheckInAction={handleCheckInAPI}
                />
                : null
            }
            {showPatientInfoScreen ?
                <PatientDemographicScreen
                    patientInfo={selectedPatient}
                    handleYesAction={() => {
                        setShowPatientInfoScreen(false)
                        setShowAppointmentSuccessScreen(true)
                    }}
                /> : null
            }
            {showNoMatchingScreen ?
                <NoMatchingScreen
                    title={t(Strings.CheckIn.WeCantFindYourAppointment)}
                    information={`${t(Strings.CheckTheInformationYouProvidedAndTryAgain)} ${t(Strings.IfTheInformationYouProvidedIsCorrectPleaseSpeakToAMemberOfOurReceptionTeam)}`}
                    matchingTitles={matchTitles}
                    matchingComponents={matchingComponents}
                    closeAction={handleHomeClick}
                    tryAgainAction={tryAgainAction}
                />
                : null
            }
            {showAppointmentSuccessScreen ?
                <CheckInSuccessScreen
                    patientName={CheckInViewModel().formatPatientName(selectedPatient)}
                    handleDoneAction={handleHomeClick}
                    selectedAppointments={selectedAppointments}
                /> : null
            }
            {showSomethingWentWrongScreen ?
                <SomethingWentWrongScreen
                    handleCloseAction={handleHomeClick}
                />
                : null
            }
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
            <LanguagePopupScreen />
        </CheckInContainerView>
    );
};

export default CheckInContainer;
