import { useTranslation } from "react-i18next";
import { ButtonText } from "../../../../../constants/StringConstant"
import { AppointmentUIEntity } from "../../../../../viewModels/CheckInViewModel"
import { FooterButtonView, CloseButton, CheckInButton } from "../../../styles/StylesCheckIn"

interface AppointmentListFooterViewProps {
    footerTitle: string,
    showCloseButton: boolean,
    showCheckInButton: boolean,
    handleCloseAction: () => void,
    handleCheckInAction: () => void,
    selectedAppointments: AppointmentUIEntity[],
    appointmentsLength: number,
}

export const AppointmentListFooter: React.FC<AppointmentListFooterViewProps> = (props: AppointmentListFooterViewProps) => {
    const { t } = useTranslation();
    return (
        <FooterButtonView>
            {props.showCloseButton ?
                <CloseButton onClick={props.handleCloseAction} > {t(ButtonText.Close)} </CloseButton>
                : null
            }
            {props.showCheckInButton ?
                <CheckInButton
                    onClick={props.handleCheckInAction}
                    isDisabled={props.selectedAppointments.length === 0 && props.appointmentsLength > 1}
                >
                    {props.footerTitle}
                </CheckInButton>
                : null
            }
        </FooterButtonView>
    )
}

export default AppointmentListFooter