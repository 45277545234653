/* AppointmentSelectionView */

import moment from "moment";
import { Strings } from "../../../../../constants/StringConstant";
import { DateFormat } from "../../../../../helpers/DateTimeHelper";
import { AppointmentTypesView, AppointmentTypesInnerView, ChooseSpecificDateButton, StyledCalendarMonthIcon, AppointmentByWeekMonthButton, AppointmentByWeekMonthButtonTitle, AppointmentByWeekMonthButtonSubtitle } from "../../../styles/StylesBookAppointment";
import { useTranslation } from "react-i18next";

export interface AppointmentListItem {
    title: string;
    description?: string;
}

interface AppointmentSelectionViewProps {
    appointments: AppointmentListItem[],
    handleChooseSpecificDateAction: (date: Date) => void,
}

const AppointmentSelectionView: React.FC<AppointmentSelectionViewProps> = (props: AppointmentSelectionViewProps) => {
    const { t } = useTranslation();
    return (
        <AppointmentTypesView>
            <AppointmentTypesInnerView>
                <ChooseSpecificDateButton
                    onClick={() => props.handleChooseSpecificDateAction(new Date())}
                    variant="contained"
                    startIcon={<StyledCalendarMonthIcon style={{fontSize: 'xx-large'}}/>}
                >
                    {t(Strings.BookAppointment.ChooseSpecificDate)}
                </ChooseSpecificDateButton>
                {props.appointments.map((appointment, index) =>
                    <AppointmentByWeekMonthButton
                        key={`${index.toString()}. ${appointment.title}`}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const dateString = appointment.description
                            if (dateString) {
                                const date = moment(dateString, DateFormat.BA_DateDescription).toDate();
                                props.handleChooseSpecificDateAction(date)
                            }
                        }}
                    >
                        <AppointmentByWeekMonthButtonTitle>{t(appointment.title)}</AppointmentByWeekMonthButtonTitle>
                        <AppointmentByWeekMonthButtonSubtitle>{appointment.description}</AppointmentByWeekMonthButtonSubtitle>
                    </AppointmentByWeekMonthButton>
                )}
            </AppointmentTypesInnerView>
        </AppointmentTypesView>
    )
}

export default AppointmentSelectionView