import { Patient } from "../../../../../../api/apiResultModels/BookedPatientResult";
import { CheckInViewModel } from "../../../../../../viewModels/CheckInViewModel";
import { PatientDemographicScreenContainer } from "../../../../styles/StylesCheckIn";
import PatientInfoHeaderView from "./PatientInfoHeaderView";
import PatientInfoScreenFooterView from "./PatientInfoScreenFooterView";
import PatientPersonalInfoView from "./PatientPersonalInfoView";


interface PatientInfoViewProps {
    patientInfo: Patient | undefined;
    handleYesAction: () => void
    handleNoAction: () => void
}

const PatientInfoView: React.FC<PatientInfoViewProps> = (props: PatientInfoViewProps) => {
    return (
        <PatientDemographicScreenContainer>
            <PatientInfoHeaderView patientName={CheckInViewModel().formatPatientName(props.patientInfo)} />
            <PatientPersonalInfoView patientInfo={props.patientInfo} />
            <PatientInfoScreenFooterView handleYesAction={props.handleYesAction} handleNoAction={props.handleNoAction} />
        </PatientDemographicScreenContainer>
    )
}

export default PatientInfoView