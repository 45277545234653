/* BookAppointmentContainer.tsx */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppointmentSessionsParameters } from '../../../../api/apiParameterModels/AppointmentSessionsParameters';
import { BookAppointmentParams } from '../../../../api/apiParameterModels/BookAppointmentParams';
import { AppointmentSession } from '../../../../api/apiResultModels/AppointmentSessionsResult';
import { Patient } from '../../../../api/apiResultModels/BookedPatientResult';
import { PatientFindResult } from '../../../../api/apiResultModels/PatientFindResult';
import { Strings } from '../../../../constants/StringConstant';
import { SlotStatus } from '../../../../enum';
import { resetToDefaultLanguage } from '../../../../helpers/CommonHelper';
import { DateFormat, formatDate, getMonthStartAndEndDates, getStartAndEndDateTime } from '../../../../helpers/DateTimeHelper';
import { isEqualIgnoreCase, isNonEmpty } from '../../../../helpers/StringHelper';
import { setAPICallInProgress } from '../../../../redux/actions/GlobalAction';
import { RootState, store, useAppDispatch } from '../../../../redux/Store';
import { SlotUIEntity } from '../../../../uiEntities/SlotUIEntity';
import { AppConstants } from '../../../../utils/constants/AppConstants';
import { internetConnectivity } from '../../../../utils/InternetConnectivity';
import { BookAppointmentViewModel, CalendarComponent } from '../../../../viewModels/BookAppointmentViewModel';
import { CheckInViewModel } from '../../../../viewModels/CheckInViewModel';
import { LoginViewModel } from '../../../../viewModels/LoginViewModel';
import Loader from '../../commonViews/Loader';
import MessageBanner from '../../commonViews/MessageBanner';
import SomethingWentWrongScreen from '../../commonViews/SomethingWentWrongScreen';
import TopBar from '../../commonViews/TopBar';
import { BookAppointmentContainerScrollableView, BookAppointmentContainerTopView, BookAppointmentContainerView } from '../../styles/StylesBookAppointment';
import MatchingContainerScreen, { MatchingComponents } from '../matchingScreen/MatchingContainerScreen';
import NoMatchingScreen from '../matchingScreen/NoMatchingScreen';
import BookAppointmentCalendarScreen from './bookAppointmentCalendar/BookAppointmentCalendarScreen';
import BookAppointmentConfirmation from './BookAppointmentConfirmation';
import BookAppointmentSelection from './bookAppointmentSelection/BookAppointmentSelection';
import BookAppointmentSlotSelection from './bookAppointmentSlotSelection/BookAppointmentSlotSelection';
import BookAppointmentSuccess from './BookAppointmentSuccess';
import BookAppointmentTypeSelection from './bookAppointmentTypeSelection/BookAppointmentTypeSelection';

export type AppointmentsByDate = {
    [key: string]: AppointmentSession[];
};

const BookAppointmentContainer: React.FC = () => {
    const [matchingComponents, setMatchingComponents] = React.useState<MatchingComponents>({ date: '', day: '', month: '', year: '', gender: '', surNameFirstLetter: '', postalCode: '' })
    const [showMatchingContainer, setShowMatchingContainer] = React.useState(true);
    const [showNoMatchingScreen, setShowNoMatchingScreen] = React.useState(false);
    const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false)
    const [currentIndex, setCurrentIndex] = React.useState<number>(0)
    const [showBookAppointmentTypeSelection, setShowBookAppointmentTypeSelection] = React.useState<boolean>(false)
    const [showBookAppointmentSelection, setShowBookAppointmentSelection] = React.useState<boolean>(false)
    const [showBookAppointmentCalendarScreen, setShowBookAppointmentCalendarScreen] = React.useState<boolean>(false)
    const [showBookAppointmentSlotSelection, setShowBookAppointmentSlotSelection] = React.useState<boolean>(false)
    const [postCodes, setPostCodes] = React.useState<string[]>([])
    const [showPostCode, setShowPostCode] = React.useState<boolean>(false)
    const isOnline = internetConnectivity.checkConnectivity();
    const dispatch = useAppDispatch();
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const [selectedPatient, setSelectedPatient] = React.useState<Patient | undefined>(undefined)
    const [nextAvailableSlot, setNextAvailableSlot] = React.useState<SlotUIEntity | undefined>(undefined)
    const [showBookAppointmentConfirmation, setShowBookAppointmentConfirmation] = React.useState<boolean>(false)
    const [showBookAppointmentSuccess, setShowBookAppointmentSuccess] = React.useState<boolean>(false)
    const [selectedSlot, setSelectedSlot] = React.useState<SlotUIEntity | undefined>(undefined)
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date())
    const [slotTypeId, setSlotTypeId] = React.useState<string>('')
    const [monthWiseSessionsByDate, setMonthWiseSessionsByDate] = React.useState<AppointmentsByDate | undefined>(undefined)
    const [weekWiseCalendarComponents, setWeekWiseCalendarComponents] = React.useState<CalendarComponent[]>([])
    const [sessionsForTheSelectedDate, setSessionsForTheSelectedDate] = React.useState<AppointmentSession[]>([])
    const matchTitles = store.getState().configSlice.appointmentMatchTitles
    const navigate = useNavigate();
    const [isShowBackOption, setIsShowBackOption] = React.useState<boolean>(false)
    const [nextAvailableAppointmentBooked, setNextAvailableAppointmentBooked] = React.useState<boolean>(false)
    const { t } = useTranslation();
    React.useEffect(() => {
        setIsShowBackOption(!(showBookAppointmentSuccess || currentIndex === 0) || showBookAppointmentCalendarScreen)
    }, [currentIndex, showBookAppointmentCalendarScreen, showBookAppointmentSuccess])

    function getInitialValue() {
        setShowMatchingContainer(false);
        setShowNoMatchingScreen(false);
        setShowSomethingWentWrongScreen(false);
        setShowBookAppointmentTypeSelection(false);
        setShowBookAppointmentSelection(false);
        setShowBookAppointmentConfirmation(false);
        setShowBookAppointmentSuccess(false);
        setShowBookAppointmentCalendarScreen(false);
        setCurrentIndex(0);
        setSelectedSlot(undefined);
    }
    function onCompletionBookAppointmentPreRequisite(matchingFields: MatchingComponents) {
        setMatchingComponents(matchingFields);
        handlePatientAPI(matchingFields);
    }
    function tryAgainAction() {
        setMatchingComponents({ date: '', day: '', month: '', year: '', gender: '', surNameFirstLetter: '', postalCode: '' });
        getInitialValue();
        setShowMatchingContainer(true);
    }
    function handleSlotTypeAction(slotTypeId: string, showAppointmentSelectionScreen: boolean = true) {
        dispatch(setAPICallInProgress(true));
        BookAppointmentViewModel().getNextAvailableSessionSlot(dispatch, slotTypeId)
            .then(nextAvailableSlot => {
                setSlotTypeId(slotTypeId);
                dispatch(setAPICallInProgress(false));
                setNextAvailableSlot(nextAvailableSlot);
                if (showAppointmentSelectionScreen) {
                    setShowBookAppointmentTypeSelection(false);
                    setShowBookAppointmentSelection(true);
                } else {
                    setShowBookAppointmentCalendarScreen(false);
                    setShowBookAppointmentSelection(true);
                }
            }).catch((error: Error) => {
                dispatch(setAPICallInProgress(false));
                handleAPIError(error);
            });
    }
    function handleBookThisAppointmentAction(slot: SlotUIEntity) {
        setNextAvailableAppointmentBooked(true)
        setSelectedSlot(slot);
        setShowBookAppointmentSelection(false);
        setShowBookAppointmentConfirmation(true);
    }
    function handleConfirmBookAppointmentAction(slot: SlotUIEntity, bookingReason: string) {
        if (slot.slotId) {
            const params: BookAppointmentParams = {
                slotId: `${slot.slotId}`,
                patientId: selectedPatient?.patientId,
                bookingReason: bookingReason,
            }
            dispatch(setAPICallInProgress(true));
            BookAppointmentViewModel().handleBookAppointment(params)
                .then((success) => {
                    dispatch(setAPICallInProgress(false));
                    if (success) {
                        getInitialValue();
                        setShowBookAppointmentSuccess(true);
                        setSelectedSlot(slot);
                    }
                })
                .catch((error: Error) => {
                    dispatch(setAPICallInProgress(false));
                    console.log(error.message)
                });
        }
    }
    const handleHomeClick = () => {
        const languages = store.getState().configSlice.allConfig?.languages ?? []
        resetToDefaultLanguage(dispatch, languages)
        navigate('/')
    }
    function handleBackClick() {
        if (showBookAppointmentTypeSelection) {
            setShowBookAppointmentTypeSelection(false);
            setShowMatchingContainer(true);
        } else if (showBookAppointmentSelection) {
            setShowBookAppointmentSelection(false);
            setShowBookAppointmentTypeSelection(true);
        } else if (showBookAppointmentConfirmation) {
            if (nextAvailableAppointmentBooked) {
                handleSlotTypeAction(slotTypeId, false);
                setShowBookAppointmentConfirmation(false);
                setShowBookAppointmentSelection(true)
            } else {
                setShowBookAppointmentConfirmation(false);
                setShowBookAppointmentSlotSelection(true);
            }
        } else if (showBookAppointmentCalendarScreen) {
            setSelectedDate(new Date())
            handleSlotTypeAction(slotTypeId, false);
            setShowBookAppointmentCalendarScreen(false);
            setShowBookAppointmentSlotSelection(false)
            setShowBookAppointmentSelection(true);
        } else if (showBookAppointmentSlotSelection) {
            setShowBookAppointmentSlotSelection(false)
            setShowBookAppointmentCalendarScreen(true);
        } else {
            setCurrentIndex(preValue => preValue > 0 ? preValue - 1 : preValue);
        }
    }
    function handleChooseSpecificDateAction(date: Date) {
        updateSession(date, true)
    }
    async function updateSession(selectedDate: Date, isShowCalendarScreen: boolean) {
        dispatch(setAPICallInProgress(true));
        const startEndDateTime = getMonthStartAndEndDates(selectedDate);
        const params: AppointmentSessionsParameters = {
            slotType: slotTypeId,
            startDate: startEndDateTime.startDateTime,
            endDate: startEndDateTime.endDateTime,
        };
        try {
            const appointmentSessions: AppointmentSession[] = await BookAppointmentViewModel().handleAppointmentSessionAPI(params);
            const sessionsByDate = BookAppointmentViewModel().convertSessionsByDate(appointmentSessions)
            setMonthWiseSessionsByDate(sessionsByDate)

            const date = formatDate(selectedDate, DateFormat.DDMMYY_Slash)
            const sessions = sessionsByDate[date]
            setSessionsForTheSelectedDate(sessions)

            if (isShowCalendarScreen) {
                setShowBookAppointmentSelection(false);
                setShowBookAppointmentCalendarScreen(true);
            }
            setSelectedDate(selectedDate);
            dispatch(setAPICallInProgress(false));
        }
        catch (error) {
            dispatch(setAPICallInProgress(false));
        }
    }
    async function handleMonthSelectionInCalendarScreen(selectedDate: Date) {
        updateSession(selectedDate, false)
    }
    async function handleDateSelectionInCalendarScreen(selectedDate: Date) {
        dispatch(setAPICallInProgress(true));
        const startEndTime = getStartAndEndDateTime(selectedDate)
        const params: AppointmentSessionsParameters = {
            slotType: slotTypeId,
            startDate: startEndTime.startDateTime,
            endDate: startEndTime.endDateTime,
        };
        try {
            const appointmentSessions: AppointmentSession[] = await BookAppointmentViewModel().handleAppointmentSessionAPI(params);
            setSelectedDate(selectedDate);
            const sessionsByDate = BookAppointmentViewModel().convertSessionsByDate(appointmentSessions)
            const date = formatDate(selectedDate, DateFormat.DDMMYY_Slash)
            const sessions = sessionsByDate[date]
            if (sessions.length > 0) {
                const sessionIDs = sessions.map(session => session?.dbid ?? -1).filter(sessionID => sessionID !== -1)
                try {
                    const slotResults = await BookAppointmentViewModel().handleAllSlotsForTheSessions(sessionIDs)
                    sessions.forEach((session, index) => {
                        session.slots = slotResults[index].slots?.filter(slot => isEqualIgnoreCase(slot.status, SlotStatus.SlotAvailable));
                    });
                    setSessionsForTheSelectedDate(sessions)
                    await handleChangeInWeekSelectionInSlotSelectionScreen(selectedDate)
                    setShowBookAppointmentSlotSelection(true);
                    setShowBookAppointmentCalendarScreen(false);
                    dispatch(setAPICallInProgress(false));
                }
                catch (error) {
                    dispatch(setAPICallInProgress(false));
                    if (error instanceof Error) {
                        console.log('Error => ', error.message)
                    }
                }
            }
        }
        catch (error) {
            dispatch(setAPICallInProgress(false));
        }
    }
    async function handleChangeInWeekSelectionInSlotSelectionScreen(selectedDate: Date) {
        dispatch(setAPICallInProgress(true));
        try {
            const allDatesOfWeek = BookAppointmentViewModel().getWeekDatesFromDate(selectedDate)
            const params = BookAppointmentViewModel().getAppointmentSessionsParametersForWeekSelection(allDatesOfWeek, slotTypeId)
            if (params) {
                const appointmentSessions: AppointmentSession[] = await BookAppointmentViewModel().handleAppointmentSessionAPI(params);
                const sessionsByDate = BookAppointmentViewModel().convertSessionsByDate(appointmentSessions)

                const allDatesOfWeek = BookAppointmentViewModel().getWeekDatesFromDate(selectedDate)
                const weekWiseCalendarComponents = sessionsByDate ? BookAppointmentViewModel().getCalendarComponent(allDatesOfWeek, sessionsByDate) : []
                setWeekWiseCalendarComponents(weekWiseCalendarComponents)
            } else {
                setWeekWiseCalendarComponents([])
            }
            dispatch(setAPICallInProgress(false));
        }
        catch (error) {
            dispatch(setAPICallInProgress(false));
        }
    }
    function handleCalendarIconSelectionInDateStrip() {
        setShowBookAppointmentSlotSelection(false);
        setShowBookAppointmentCalendarScreen(true);
    }
    async function handlePatientAPI(matchingComponents: MatchingComponents) {
        if (!isOnline) {
            return;
        }
        dispatch(setAPICallInProgress(true));
        LoginViewModel().validateSession(dispatch)
            .then((isValidSession) => {
                if (isValidSession) {
                    CheckInViewModel().initiatePatientFindAPI(matchingComponents)
                        .then(async (patientFindResult) => {
                            await handlePatientFindResult(patientFindResult, matchingComponents)
                        })
                        .catch((error: Error) => {
                            handleAPIError(error)
                        })
                } else {
                    handleAPIError()
                }
            })
            .catch((error: Error) => {
                handleAPIError(error)
            })
    }
    async function handlePatientFindResult(patientFindResult: PatientFindResult, matchingComponents: MatchingComponents) {
        dispatch(setAPICallInProgress(false))
        if (patientFindResult?.patients && patientFindResult.patients.length === 1 && isNonEmpty(patientFindResult.patients[0].patientId)) {
            setSelectedPatient(patientFindResult.patients[0]);
            setShowMatchingContainer(false)
            setShowBookAppointmentTypeSelection(true)
            setPostCodes([]);
            setShowPostCode(false);
        } else if (patientFindResult?.patients && patientFindResult.patients.length > 1) {
            handleMultiplePatientFromSearchResult(patientFindResult.patients)
        } else {
            handleNotYouAction(matchingComponents);
        }
    }
    function handleMultiplePatientFromSearchResult(patients: Patient[]) {
        dispatch(setAPICallInProgress(false))
        if (isNonEmpty(matchingComponents.postalCode)) {
            handleNotYouAction(matchingComponents);
        } else {
            const postCodes = CheckInViewModel().getUniqueNonEmptyPostCodesFromPatients(patients);
            const updatedPostCodes = CheckInViewModel().addRandomPostCodesIfNeeded(postCodes, AppConstants.MaximumPostCodes);
            setPostCodes(updatedPostCodes);
            setShowPostCode(true);
        }
    }
    function handlePostalCodeSelection(matchingComponents: MatchingComponents) {
        setMatchingComponents(matchingComponents);
        handlePatientAPI(matchingComponents)
    }
    function handleNotYouAction(matchingComponents: MatchingComponents): void {
        dispatch(setAPICallInProgress(false))
        getInitialValue();
        setMatchingComponents(matchingComponents);
        setShowNoMatchingScreen(true);
    }
    function handleAPIError(error?: Error) {
        console.log('error -> ', JSON.stringify(error))
        dispatch(setAPICallInProgress(false))
        getInitialValue();
        setShowSomethingWentWrongScreen(true)
    }
    function handleSlotSelection(selectedSlot: SlotUIEntity) {
        setSelectedSlot(selectedSlot)
        setShowBookAppointmentSlotSelection(false);
        setShowBookAppointmentConfirmation(true);
    }

    return (
        <BookAppointmentContainerView>
            <BookAppointmentContainerTopView>
                <TopBar
                    isShowHomeButton={true}
                    isShowBackOption={isShowBackOption}
                    handleBackClick={handleBackClick}
                />
                {showMatchingContainer ? <MessageBanner message={t(Strings.WeAreAskingTheseQuestionsToHelpIdentifyYou)} /> : null}
            </BookAppointmentContainerTopView>
            {showMatchingContainer ?
                <BookAppointmentContainerScrollableView>
                    <MatchingContainerScreen
                        matchingComponents={matchingComponents}
                        matchTitles={matchTitles}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        onCompletionMatchingContainer={onCompletionBookAppointmentPreRequisite}
                        onNoneOfTheAboveClickOnPostCode={(matchingComponents) => {
                            setMatchingComponents(matchingComponents);
                            handleNotYouAction(matchingComponents);
                        }}
                        showPostalCodes={showPostCode}
                        postalCodes={postCodes}
                        onClickPostCode={handlePostalCodeSelection}
                    />
                </BookAppointmentContainerScrollableView>
                : null
            }
            {showNoMatchingScreen ?
                <NoMatchingScreen
                    title={t(Strings.BookAppointment.WeCantFindYourDetails)}
                    information={`${t(Strings.CheckTheInformationYouProvidedAndTryAgain)} ${t(Strings.IfTheInformationYouProvidedIsCorrectPleaseSpeakToAMemberOfOurReceptionTeam)}`}
                    matchingTitles={matchTitles}
                    matchingComponents={matchingComponents}
                    closeAction={handleHomeClick}
                    tryAgainAction={tryAgainAction}
                />
                : null
            }
            {showSomethingWentWrongScreen ?
                <SomethingWentWrongScreen
                    handleCloseAction={handleHomeClick}
                />
                : null
            }
            {showBookAppointmentTypeSelection && selectedPatient ?
                <BookAppointmentTypeSelection
                    patientName={CheckInViewModel().formatPatientName(selectedPatient)}
                    handleNotYouAction={() => handleNotYouAction(matchingComponents)}
                    handleSlotTypeAction={handleSlotTypeAction}
                />
                : null
            }
            {showBookAppointmentSelection ?
                <BookAppointmentSelection
                    handleChooseSpecificDateAction={handleChooseSpecificDateAction}
                    handleBookThisAppointmentAction={handleBookThisAppointmentAction}
                    nextAvailableSlot={nextAvailableSlot}
                /> : null
            }
            {showBookAppointmentCalendarScreen ?
                <BookAppointmentCalendarScreen
                    selectedDate={selectedDate}
                    handleDateSelection={handleDateSelectionInCalendarScreen}
                    handleMonthSelection={handleMonthSelectionInCalendarScreen}
                    monthWiseSessionsByDate={monthWiseSessionsByDate}
                /> : null
            }
            {showBookAppointmentSlotSelection && selectedDate ?
                <BookAppointmentSlotSelection
                    selectedDate={selectedDate}
                    sessionsForTheSelectedDate={sessionsForTheSelectedDate}
                    handleDateSelection={handleDateSelectionInCalendarScreen}
                    handleCalendarIconSelection={handleCalendarIconSelectionInDateStrip}
                    handleSlotSelection={handleSlotSelection}
                    handleChangeInWeekSelection={handleChangeInWeekSelectionInSlotSelectionScreen}
                    weekWiseCalendarComponents={weekWiseCalendarComponents}
                /> : null
            }
            {showBookAppointmentConfirmation && selectedSlot ?
                <BookAppointmentConfirmation
                    selectedSlot={selectedSlot}
                    handleConfirmBookAppointmentAction={handleConfirmBookAppointmentAction}
                /> : null
            }
            {showBookAppointmentSuccess && selectedSlot ?
                <BookAppointmentSuccess
                    selectedSlot={selectedSlot}
                    handleDoneAction={handleHomeClick}
                /> : null
            }
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
        </BookAppointmentContainerView>
    );
};

export default BookAppointmentContainer;
