
import { Button, styled } from "@mui/material";
import { ColorConstant } from "../../../constants/ColorConstant";

/* HomeScreen */
export const HomeScreenContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100vh',
    width: '100%',
    backgroundColor: ColorConstant.WHITE,
    userSelect: 'none',
});

export const HomeLogo = styled('img') ({
    height: 100, 
    alignSelf: 'center', 
    objectFit: 'contain',
});

export const HomeScreenOptionsView = styled('div') ({
    justifyContent: 'center',
    alignSelf: 'center',
    flex: 'auto',
    height: '100%',
    flexDirection: 'column',
    display: 'inline-block',
    overflowY: 'auto',
});

export const CheckInButton = styled(Button)({
    display: 'flex',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: ColorConstant.EMERALD_STONE,
    borderRadius: 5,
    marginTop: '10%',
    width: '100%',
    height: '30%',
    fontSize: '5vw',
    color: ColorConstant.WHITE,
    minWidth: 100,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    },
});

export const BookAppointmentButton = styled(Button)({
    display: 'flex',
    backgroundColor: ColorConstant.WHITE,
    cursor: 'pointer',
    marginTop: '5%',
    border: `4px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`,
    borderRadius: 5,
    minWidth: 100,
    width: '100%',
    height: '20%',
    fontSize: '4vw',
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
});

export const SiteMapQuestionnairesView = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    width: '100%',
    height: '10%',
    marginTop: '10%',
});

export const SiteMapQuestionnairesButton = styled(Button)({
    display: 'flex',
    backgroundColor: ColorConstant.WHITE,
    cursor: 'pointer',
    border: `4px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`,
    borderRadius: 5,
    fontSize: '2vw',
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textTransform: 'none',
});