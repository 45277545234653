/* BookAppointmentTypeSelectionHeaderView */

import { useTranslation } from "react-i18next";
import { Strings } from "../../../../../constants/StringConstant"
import { BookAppointmentTypeSelectionHeader, BookAppointmentTypeSelectionTopContainer, BookAppointmentTypeSelectionUserNameContainer, BookAppointmentTypeSelectionHiText, BookAppointmentTypeSelectionUserNameText, BookAppointmentTypeSelectionNotYouButton } from "../../../styles/StylesBookAppointment"

const BookAppointmentTypeSelectionHeaderView: React.FC<{ patientName: string, handleNotYouAction: () => void }> = ({ patientName, handleNotYouAction }) => {
    const { t } = useTranslation();
    return (
        <BookAppointmentTypeSelectionHeader>
            <BookAppointmentTypeSelectionTopContainer>
                <BookAppointmentTypeSelectionUserNameContainer>
                    <BookAppointmentTypeSelectionHiText>
                        {t(Strings.Hi)}
                    </BookAppointmentTypeSelectionHiText>
                    <BookAppointmentTypeSelectionUserNameText>
                        {patientName}
                    </BookAppointmentTypeSelectionUserNameText>
                </BookAppointmentTypeSelectionUserNameContainer>
                <BookAppointmentTypeSelectionNotYouButton onClick={handleNotYouAction}>
                    {t(Strings.NotYou)}
                </BookAppointmentTypeSelectionNotYouButton>
            </BookAppointmentTypeSelectionTopContainer>
        </BookAppointmentTypeSelectionHeader>
    )
}

export default BookAppointmentTypeSelectionHeaderView