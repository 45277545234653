import React, { useEffect } from 'react';
import './App.css';
import { setCurrentDate, setCurrentOrg, setCurrentTime, setOrgs } from './redux/actions/GlobalAction';
import { useAppDispatch } from './redux/Store';
import { setAllConfig, setAppointmentMatchTitles, setAppointmentSlotTypes, setEarlyArrival, setModules, setPatientMatchTitles, setShowDemographicDetails } from './redux/actions/ConfigAction';
import { Config } from './models/Config';
import DateTimeHelper from './helpers/DateTimeHelper';
import RouterComponent from './navigation/RouterComponent';
import LanguagePopupScreen from './view/web/commonViews/LanguagePopupScreen';
import { resetToDefaultLanguage } from './helpers/CommonHelper';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const dateTimeHelper = new DateTimeHelper();

  useEffect(() => {
    fetch('/config.txt')
      .then(response => response.text())
      .then(text => {
        const configData = JSON.parse(text);

        if (Array.isArray(configData) && configData.length > 0 && typeof configData[0] !== 'string') {
          const configString = JSON.stringify(configData[0])
          const config: Config = JSON.parse(configString)
          setupConfig(config)
        }
      })
  });

  React.useEffect(() => {
    // AnalyticsManager.initializeDataDog();
  }, []);

  const setupConfig = (config: Config) => {
    dispatch(setAllConfig(config))
    dispatch(setCurrentOrg(undefined))
    dispatch(setOrgs(config.OrganisationList ? config.OrganisationList : []))
    dispatch(setPatientMatchTitles((config.PatientMatchTitle ?? '').split(',').map(matchTitle => matchTitle.trim())))
    dispatch(setAppointmentMatchTitles((config.AppointmentMatchTitle ?? '').split(',').map(matchTitle => matchTitle.trim())))
    dispatch(setAppointmentSlotTypes(config.SlotTypes ?? []))
    dispatch(setModules(config.Module ? config.Module : []))
    dispatch(setEarlyArrival(config.EarlyArrival ?? 0))
    dispatch(setShowDemographicDetails(config.ShowDemographicDetails ?? false))
    resetToDefaultLanguage(dispatch, config.languages)
  }

  useEffect(() => {
    const setDate = () => {
      const currentDateTime = new Date()
      const currentDate = dateTimeHelper.formatDate(currentDateTime, { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' });
      const currentTime = dateTimeHelper.formatTime(currentDateTime, { hour: '2-digit', minute: '2-digit', hour12: false }).toUpperCase();
      dispatch(setCurrentDate(currentDate))
      dispatch(setCurrentTime(currentTime))
    }
    setDate()
    const timerID = setInterval(() => {
      setDate()
    }, 1000);
    return () => {
      clearInterval(timerID);
    };
  });

  return (
    <div className="App">
      <RouterComponent />
      <LanguagePopupScreen/>
    </div>
  );
}

export default App;
