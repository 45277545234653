import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import i18n, { languages } from "../locales/i18n";
import { Language } from "../models/Config";
import { setSelectedLanguage } from "../redux/actions/GlobalAction";
import { isNonEmpty } from "./StringHelper";

export const addSpacesBetweenCharacters = (text: string) => {
    return text.split('').join(' ');
}

export const padDigitsInPrefix = (numStr: string, digits: number): string => {
    return numStr.padStart(digits, '0');
};

export const padDigitsInSuffix = (numStr: string, digits: number): string => {
    if (numStr.length >= digits) {
        return numStr
    }
    const zerosNeeded = digits - numStr.length;
    return numStr + '0'.repeat(zerosNeeded);
};

export const getDaySuffix = (day: number): string => {
    if (day % 10 === 1 && day !== 11) return 'st';
    if (day % 10 === 2 && day !== 12) return 'nd';
    if (day % 10 === 3 && day !== 13) return 'rd';
    return 'th';
};

export const getMonth = (month: string): string => {
    const monthInt = parseInt(month)
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return (isNaN(monthInt) || monthInt < 1 || monthInt > 12) ? '' : months[monthInt - 1]
}

export const convertNumberToWord = (number: number) => {
    const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
    const teens = ["eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
    const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    if (number === 0) return "zero";
    if (number < 10) return units[number];
    if (number > 10 && number < 20) return teens[number - 11];
    if (number >= 10 && number < 100) {
        const ten = Math.floor(number / 10);
        const unit = number % 10;
        return tens[ten] + (unit ? "-" + units[unit] : "");
    }
    return "";
};

export const removeLastCharacter = (srcString: string): string => {
    if (srcString.length === 0) {
        return ''
    } else {
        return srcString.slice(0, -1)
    }
}

export const maskInfo = (email: string, position: 'front' | 'back' = 'back', visibleChars: number = 3): string => {
    const [localPart, domain] = email.split('@');
    if (domain) {
        if (localPart.length <= 2) {
            return localPart; // Not enough characters to mask
        }
        // Mask all characters except the first and last
        return localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1] + `@${domain}`
    } else {
        let localPartMasked: string;
        if (position === 'front') {
            localPartMasked = `${localPart.substring(0, visibleChars)}${'*'.repeat(localPart.length - visibleChars)}`;
        } else {
            localPartMasked = `${'*'.repeat(localPart.length - visibleChars)}${localPart.substring(localPart.length - visibleChars)}`;
        }
        return localPartMasked
    }
};

export function getLanguageText(languageName?: string): string {
    if (languageName && isNonEmpty(languageName)) {
        return languages[languageName]
    } else {
        return ""
    }
}

export function resetToDefaultLanguage(dispatch: Dispatch<UnknownAction>, languages: Language[] | undefined) {
    if (languages && languages.length > 0) {
        const sortedLanguagesByOrder = [...languages].sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        const defaultLanguage = sortedLanguagesByOrder[0]
        dispatch(setSelectedLanguage(defaultLanguage))
        i18n.changeLanguage(defaultLanguage.languageCode);
    }
}