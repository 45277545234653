/* BookAppointmentCalendarFooterView */

import { useTranslation } from "react-i18next";
import { Strings } from "../../../../../constants/StringConstant"
import { BookAppointmentCalendarScreenFooterContainer, BookAppointmentCalendarScreenFooterInnerContainer, BookAppointmentCalendarScreenFooterInnerContainerAppointmentLegend, BookAppointmentCalendarScreenFooterInnerContainerNoAppointmentLegend, BookAppointmentCalendarScreenFooterInnerContainerText, BookAppointmentCalendarScreenFooterInnerContainerTodayLegend } from "../../../styles/StylesBookAppointment"

const BookAppointmentCalendarFooterView = () => {
    const { t } = useTranslation();
    return (
        <BookAppointmentCalendarScreenFooterContainer>
            <BookAppointmentCalendarScreenFooterInnerContainer>
                <BookAppointmentCalendarScreenFooterInnerContainerAppointmentLegend/>
                <BookAppointmentCalendarScreenFooterInnerContainerText>{t(Strings.BookAppointment.Appointments)}</BookAppointmentCalendarScreenFooterInnerContainerText>
            </BookAppointmentCalendarScreenFooterInnerContainer>
            <BookAppointmentCalendarScreenFooterInnerContainer>
                <BookAppointmentCalendarScreenFooterInnerContainerNoAppointmentLegend />
                <BookAppointmentCalendarScreenFooterInnerContainerText>{t(Strings.BookAppointment.NoAppointments)}</BookAppointmentCalendarScreenFooterInnerContainerText>
            </BookAppointmentCalendarScreenFooterInnerContainer>
            <BookAppointmentCalendarScreenFooterInnerContainer>
                <BookAppointmentCalendarScreenFooterInnerContainerTodayLegend/>
                <BookAppointmentCalendarScreenFooterInnerContainerText>{t(Strings.BookAppointment.Today)}</BookAppointmentCalendarScreenFooterInnerContainerText>
            </BookAppointmentCalendarScreenFooterInnerContainer>
        </BookAppointmentCalendarScreenFooterContainer>
    )
}

export default BookAppointmentCalendarFooterView