/* ConfigReducer.ts */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Config, Module, SlotType } from '../../models/Config';

export type ConfigReducer = {
    allConfig: Config | undefined,
    patientMatchTitles: string[],
    appointmentMatchTitles: string[],
    modules: Module[],
    earlyArrival: number | undefined,
    lateArrival: number | undefined,
    appointmentSlotTypes: SlotType[]
    showDemographicDetails: boolean
}

const initialState: ConfigReducer = {
    allConfig: undefined,
    patientMatchTitles: [],
    appointmentMatchTitles: [],
    modules: [],
    earlyArrival: undefined,
    lateArrival: undefined,
    appointmentSlotTypes: [],
    showDemographicDetails: false,
}

export const configSlice = createSlice({
    name: 'config',
    initialState: initialState,
    reducers: {
        setAllConfig: (state, action: PayloadAction<Config>) => {
            state.allConfig = action.payload
        },
        setPatientMatchTitles: (state, action: PayloadAction<string[]>) => {
            state.patientMatchTitles = action.payload
        },
        setAppointmentMatchTitles: (state, action: PayloadAction<string[]>) => {
            state.appointmentMatchTitles = action.payload
        },
        setModules: (state, action: PayloadAction<Module[]>) => {
            state.modules = action.payload
        },
        setEarlyArrival: (state, action: PayloadAction<number>) => {
            state.earlyArrival = action.payload
        },
        setLateArrival: (state, action: PayloadAction<number>) => {
            state.lateArrival = action.payload
        },
        setAppointmentSlotTypes: (state, action: PayloadAction<SlotType[]>) => {
            state.appointmentSlotTypes = action.payload
        },
        setShowDemographicDetails: (state, action: PayloadAction<boolean>) => {
            state.showDemographicDetails = action.payload
        },
    }
})

export default configSlice.reducer;