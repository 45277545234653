/* YearGridComponent.tsx */

import React from 'react';
import { Grid } from '@mui/material';
import { NoneOfTheAboveButton, YearComponentFooterView, YearGridButton, YearGridComponentContainer, YearGridContainer } from '../../styles/StylesMatchingScreen';

interface YearGridComponentProps {
    years: string[]
    onYearClick: (item: number) => void;
    onNoneOfTheAboveClick: () => void;
    footerText: string;
    gridAlignment: 'center' | 'left' | 'right';
}

const YearGridComponent: React.FC<YearGridComponentProps> = (props: YearGridComponentProps) => {
    return (
        <YearGridComponentContainer data-testid="YearGridComponentContainer">
            <YearGridContainer data-testid="YearGridContainer">
                <Grid data-testid="YearGridComponentOuterGrid" container spacing={3} marginTop={2} justifyContent={props.gridAlignment}>
                    {props.years.map((year, index) => {
                        return (
                            <Grid data-testid="YearGridComponentInnerGrid" item key={`${index}.${year}`}>
                                <YearGridButton data-testid="YearGridButton"
                                    onClick={() => props.onYearClick(index)}
                                    variant="contained"
                                >
                                    {year}
                                </YearGridButton>
                            </Grid>
                        )
                    })}
                </Grid>
            </YearGridContainer>
            <YearComponentFooterView data-testid="YearComponentFooterView">
                <NoneOfTheAboveButton data-testid="NoneOfTheAboveButton" onClick={props.onNoneOfTheAboveClick}>{props.footerText}</NoneOfTheAboveButton>
            </YearComponentFooterView>
        </YearGridComponentContainer>
    );
};

export default YearGridComponent;