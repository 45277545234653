import { injectable } from "inversify";

import { APIClient } from "../APIClient";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { AppConfig } from "../../utils/AppConfig";
import { PatientFindParameters } from "../apiParameterModels/PatientFindParameters";
import { IPatientRepository } from "../interfaces/IPatientRepository";
import { BookedPatientParameters } from "../apiParameterModels/BookedPatientParameters";

@injectable()
export class PatientRepository implements IPatientRepository {
    getBookedPatients(params: BookedPatientParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            APIClient.postRequest(
                DD_APIEndPointName.BookedPatient_API_Endpoint,
                AppConfig.BookedPatients_API_Endpoint,
                params)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
    getPatientSearchResults(params: PatientFindParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            APIClient.postRequest(
                DD_APIEndPointName.PatientSearch_API_Endpoint,
                AppConfig.PatientSearch_API_Endpoint,
                params)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}