/* DateSelector.tsx */

import React from "react";
import { ColorConstant } from "../../../../../constants/ColorConstant";
import { DateType } from "../../../../../enum";
import { addSpacesBetweenCharacters } from "../../../../../helpers/CommonHelper";
import { isEqualIgnoreCase } from "../../../../../helpers/StringHelper";
import { DateSelectorContainer, DateSelectorComponentContainer, DateSelectorComponentText, DateSelectorDashText, DateSelectorSlashText } from "../../../styles/StylesMatchingScreen";
import { DateComponents } from "./DateComponent";

interface DateSelectorComponentProps {
    dateComponent: DateComponents
    dateComponentSelected: DateComponentSelected
}

export interface DateComponentSelected {
    isYearSelected: boolean,
    isMonthSelected: boolean,
    isDateSelected: boolean,
}

const DateSelectorComponent: React.FC<DateSelectorComponentProps> = (props: DateSelectorComponentProps) => {

    const getDashColor = (isSelected: boolean) => {
        return isSelected ? ColorConstant.CERAMIC_BLUE_TURQUOISE : 'transparent'
    }

    const getTextColor = (text: string) => {
        return isNaN(parseInt(text)) ? ColorConstant.GREY : ColorConstant.CERAMIC_BLUE_TURQUOISE
    }

    return (
        <DateSelectorContainer>

            <DateSelectorComponentContainer>
                <DateSelectorComponentText style={{ color: getTextColor(props.dateComponent.date) }}>
                    {addSpacesBetweenCharacters(isEqualIgnoreCase(props.dateComponent.date, DateType.DD) ? DateType.DD : props.dateComponent.date)}
                </DateSelectorComponentText>
                <DateSelectorDashText style={{ background: getDashColor(props.dateComponentSelected.isDateSelected) }}> </DateSelectorDashText>
            </DateSelectorComponentContainer>

            <DateSelectorSlashText> / </DateSelectorSlashText>

            <DateSelectorComponentContainer>
                <DateSelectorComponentText style={{ color: getTextColor(props.dateComponent.month) }}>
                    {addSpacesBetweenCharacters(isEqualIgnoreCase(props.dateComponent.month, DateType.MM) ? DateType.MM : props.dateComponent.month)}
                </DateSelectorComponentText>
                <DateSelectorDashText style={{ background: getDashColor(props.dateComponentSelected.isMonthSelected) }}> </DateSelectorDashText>
            </DateSelectorComponentContainer>

            <DateSelectorSlashText> / </DateSelectorSlashText>
            
            <DateSelectorComponentContainer style={{minWidth: '130px'}}>
                <DateSelectorComponentText style={{ color: getTextColor(props.dateComponent.year) }}>
                    {addSpacesBetweenCharacters(isEqualIgnoreCase(props.dateComponent.year, DateType.YYYY) ? DateType.YYYY : props.dateComponent.year)}
                </DateSelectorComponentText>
                <DateSelectorDashText style={{ background: getDashColor(props.dateComponentSelected.isYearSelected) }}> </DateSelectorDashText>
            </DateSelectorComponentContainer>

        </DateSelectorContainer>
    );
};

export default DateSelectorComponent;
