/* MonthGridComponent.tsx */

import React from 'react';
import { Grid } from '@mui/material';
import { MonthGridButton, MonthGridComponentContainer } from '../../styles/StylesMatchingScreen';
import { MatchingViewModel } from '../../../../viewModels/MatchingViewModel';
import { useTranslation } from 'react-i18next';

interface GridProps {
    gridAlignment: 'center' | 'left' | 'right',
    onMonthClick: (item: string) => void;
}

const MonthGridComponent: React.FC<GridProps> = (props: GridProps) => {
    const months = MatchingViewModel().getMonths();
    const { t } = useTranslation();
    return (
        <MonthGridComponentContainer data-testid="MonthGridComponentContainer">
            <Grid data-testid="MonthGridComponentOuterGrid" container spacing={3} marginTop={2} justifyContent={props.gridAlignment}>
                {months.map((month, index) => {
                    return (
                        <Grid data-testid="MonthGridComponentInnerGrid" item key={`${index}.${month}`}>
                            <MonthGridButton data-testid="MonthGridButton"
                                onClick={() => props.onMonthClick(`${index + 1}`)}
                                variant="contained"
                            >
                                {t(month)}
                            </MonthGridButton>
                        </Grid>
                    )
                })}
            </Grid>
        </MonthGridComponentContainer>
    );
};

export default MonthGridComponent;