/* AppointmentItem.tsx */

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import { ColorConstant } from '../../../../../constants/ColorConstant';
import { Strings } from '../../../../../constants/StringConstant';
import { AppointmentItemContainerButton, AppointmentItemTextView, AppointmentItemTimeText, AppointmentItemWithText, AppointmentItemSessionHolderNameText, NoAppointmentItemContainerView, NoAppointmentItemTextView, NoAppointmentItemTextTopView, NoAppointmentItemTimeText, NoAppointmentItemWithText, NoAppointmentItemSessionHolderNameText, NoAppointmentItemDescriptionText } from '../../../styles/StylesCheckIn';
import { convertTo12HourFormat } from '../../../../../helpers/DateTimeHelper';
import { AppointmentUIEntity } from '../../../../../viewModels/CheckInViewModel';
import { useTranslation } from 'react-i18next';
export interface AppointmentItemProps {
    earlyArrival: number,
    appointment: AppointmentUIEntity
    isSelected: boolean,
    handleAppointmentSelection: () => void
}

export const AppointmentItem: React.FC<AppointmentItemProps> = (props: AppointmentItemProps) => {
    const { t } = useTranslation();
    const isAppointmentAvailable = !props.appointment.isEarlyAppointment && !props.appointment.isLateAppointment
    const appointmentTime = convertTo12HourFormat(props.appointment.appointmentTime ?? '')
    if (isAppointmentAvailable) {
        return (
            <AppointmentItemContainerButton onClick={props.handleAppointmentSelection}>
                {props.isSelected ?
                    <CheckBoxRoundedIcon sx={{ color: ColorConstant.WHITE, fontSize: 40, marginRight: '10px' }} />
                    :
                    <CheckBoxOutlineBlankRoundedIcon sx={{ color: ColorConstant.WHITE, fontSize: 40, marginRight: '10px', }} />
                }
                <AppointmentItemTextView>
                    <AppointmentItemTimeText>{appointmentTime}</AppointmentItemTimeText>
                    <AppointmentItemWithText> {t(Strings.CheckIn.with)} </AppointmentItemWithText>
                    <AppointmentItemSessionHolderNameText>{props.appointment.sessionHolderName}</AppointmentItemSessionHolderNameText>
                </AppointmentItemTextView>
            </AppointmentItemContainerButton>
        )
    } else {
        let descriptionText = props.appointment.isEarlyAppointment ? `${t(Strings.CheckIn.YouAreTooEarlyToCheckInForThisAppointment)} ${t(Strings.CheckIn.YouCanArriveUpToSomeMinutesEarly)}`.replace('##', `${props.earlyArrival}`) : ''
        descriptionText = props.appointment.isLateAppointment ? `${t(Strings.CheckIn.YouAreTooLateToCheckInForThisAppointment)} ${t(Strings.CheckIn.SpeakToOneOfOurReceptionTeam)}` : descriptionText
        return (
            <NoAppointmentItemContainerView>
                <ErrorOutlineRoundedIcon sx={{ color: ColorConstant.RED_POWER, fontSize: 55, }} />
                <NoAppointmentItemTextView>
                    <NoAppointmentItemTextTopView>
                        <NoAppointmentItemTimeText>{appointmentTime}</NoAppointmentItemTimeText>
                        <NoAppointmentItemWithText> {t(Strings.CheckIn.with)} </NoAppointmentItemWithText>
                        <NoAppointmentItemSessionHolderNameText>{props.appointment.sessionHolderName}</NoAppointmentItemSessionHolderNameText>
                    </NoAppointmentItemTextTopView>
                    <NoAppointmentItemDescriptionText>{descriptionText}</NoAppointmentItemDescriptionText>
                </NoAppointmentItemTextView>
            </NoAppointmentItemContainerView>
        )
    }
}