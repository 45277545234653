import { store } from "../redux/Store";

export const getAccessToken = () => {
    return store.getState().tokenSlice.accessToken;
};

export const getBaseURL = () => {
    return 'https://ewids.int.emishealthsolutions.com/hub/automatedArrivals/'
};

export const getTokenBaseURL = () => {
  return 'https://identity.int.emishealthsolutions.com/';
};