import { useTranslation } from "react-i18next"
import { ModuleEnum } from "../../../../enum"
import { isEqualIgnoreCase } from "../../../../helpers/StringHelper"
import { Module } from "../../../../models/Config"
import { HomeScreenOptionsView, BookAppointmentButton, SiteMapQuestionnairesView, SiteMapQuestionnairesButton, CheckInButton } from "../../styles/StylesHome"

interface HomeScreenProps {
    handleCheckInAction: () => void
    handleBookAppointmentAction: () => void
    siteMapAction: () => void
    questionnairesAction: () => void
    modules: Module[]
    portrait: boolean
    mobile: boolean
}

const HomeScreenOptions: React.FC<HomeScreenProps> = (props: HomeScreenProps) => {
    const { t } = useTranslation();
    const arrivalModule = props.modules.find(module => isEqualIgnoreCase(module.ModuleName, ModuleEnum.Arrival))
    const makeAppointmentModule = props.modules.find(module => isEqualIgnoreCase(module.ModuleName, ModuleEnum.MakeAppointment))
    const siteMapModule = props.modules.find(module => isEqualIgnoreCase(module.ModuleName, ModuleEnum.SiteMap))
    const questionnairesModule = props.modules.find(module => isEqualIgnoreCase(module.ModuleName, ModuleEnum.Questionnaires))
    const footerButtonFlex = siteMapModule && questionnairesModule ? 1 : 0.5

    return (
        <HomeScreenOptionsView sx={{width: props.mobile || props.portrait ? '80%' : '50%'}} data-testid="HomeScreenOptions">

            {arrivalModule?.ModuleNameToDisplay ?
                <CheckInButton data-testid="CheckInButton" onClick={props.handleCheckInAction}>
                    {t(arrivalModule.ModuleNameToDisplay)}
                </CheckInButton>
                : null
            }

            {makeAppointmentModule?.ModuleNameToDisplay ?
                <BookAppointmentButton data-testid="BookAppointmentButton"
                    onClick={props.handleBookAppointmentAction}
                >
                    {t(makeAppointmentModule.ModuleNameToDisplay)}
                </BookAppointmentButton>
                : null
            }

            {siteMapModule || questionnairesModule ?
                <SiteMapQuestionnairesView  data-testid="SiteMapQuestionnairesView">
                    {siteMapModule?.ModuleNameToDisplay ?
                        <SiteMapQuestionnairesButton 
                            data-testid="SiteMapButton"
                            onClick={props.siteMapAction}
                            style={{ flex: footerButtonFlex }}
                        >
                            {t(siteMapModule.ModuleNameToDisplay)}
                        </SiteMapQuestionnairesButton>
                        : null
                    }

                    {questionnairesModule?.ModuleNameToDisplay ?
                        <SiteMapQuestionnairesButton
                            data-testid="QuestionnairesButton"
                            onClick={props.questionnairesAction}
                            style={{ flex: footerButtonFlex }}
                        >
                            {t(questionnairesModule.ModuleNameToDisplay)}
                        </SiteMapQuestionnairesButton>
                        : null
                    }
                </SiteMapQuestionnairesView>
                : null
            }

        </HomeScreenOptionsView>
    );
};

export default HomeScreenOptions