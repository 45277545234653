import { getAccessToken, getBaseURL, getTokenBaseURL } from "../utils/ApiRequestUtil";
import { DD_APIEndPointName } from "../utils/DDAPIEndpointName";

export class APIClient {

    static getRequest(apiName: DD_APIEndPointName, url: string, data: any): Promise<string> {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getAccessToken()}`,
            },
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const responseString = await response.text()
                return responseString;
            })
            .catch(error => {
                console.error('GET request failed:', error);
                throw error;
            });
    }

    static postTokenRequest(url: string, data: any): Promise<string> {
        const apiURL = `${getTokenBaseURL()}${url}`

        return window.electron.postTokenRequest(apiURL, data)
            .then(async responseString => {
                return responseString;
            })
            .catch(error => {
                console.error('POST request failed:', error);
                throw error;
            });
    }

    static postRequest(apiName: DD_APIEndPointName, url: string, data: any): Promise<any> {
        const apiURL = `${getBaseURL()}${url}`
        const accessToken = `Bearer ${getAccessToken()}`

        return window.electron.postRequest(apiName, apiURL, accessToken,data)
            .then(async responseString => {
                return responseString;
            })
            .catch(error => {
                console.error('POST request failed:', error);
                throw error;
            });
    }
}