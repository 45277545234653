/* BookAppointmentConfirmation.tsx */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Strings } from '../../../../constants/StringConstant';
import { DateFormat, formatDateString } from '../../../../helpers/DateTimeHelper';
import { isEmpty } from '../../../../helpers/StringHelper';
import { store } from '../../../../redux/Store';
import { SlotUIEntity } from '../../../../uiEntities/SlotUIEntity';
import TextKeyboardComponent from '../../commonViews/TextKeyboardComponent';
import { BookAppointmentButton, BookAppointmentConfirmationContainer, BookAppointmentConfirmationInnerContainer, DividerInConfirmation, FooterButtonView, QuestionnaireKeyboardContainerView, QuestionnaireTextView, ReasonContainer, ReasonFooterText, ReasonHeaderText, SessionHolderNameTextInConfirmation, SlotContentView, SlotDateTextInConfirmation, SlotInfoView, SlotLocationTextInConfirmation, SlotLocationTextInConfirmationContainer, SlotTimeTextInConfirmation, SlotTimeTypeView, SlotTypeTextInConfirmation, SlotView, TitleText } from '../../styles/StylesBookAppointment';

interface BookAppointmentConfirmationProps {
    handleConfirmBookAppointmentAction: (slot: SlotUIEntity, bookingReason: string) => void
    selectedSlot: SlotUIEntity
}

const BookAppointmentConfirmation: React.FC<BookAppointmentConfirmationProps> = (props: BookAppointmentConfirmationProps) => {
    const { t } = useTranslation();

    const isShowReasonForAppointment: boolean = store.getState().configSlice.allConfig?.AppointmentReason != null
    const isMandatory_ReasonForAppointment: boolean = store.getState().configSlice.allConfig?.AppointmentReason ?? false

    const formattedDate = props.selectedSlot.date ? formatDateString(props.selectedSlot.date, DateFormat.DDMMYY_Slash, DateFormat.dddd_Do_MMMM_YYYY) : ''
    const startTime = props.selectedSlot.startDateTime ?? ''
    const endTime = props.selectedSlot.endDateTime ?? ''
    const [showKeyboard, setShowKeyboard] = React.useState<boolean>(isShowReasonForAppointment)

    const [reason, setReason] = React.useState<string>('')
    const [reasonSelected, setReasonSelected] = React.useState<boolean>(true)

    function onKeyClick(text: string) {
        if (reasonSelected) {
            setReason(`${reason}${text}`)
        }
    }

    function onDeleteClick() {
        if (reasonSelected) {
            setReason(reason.slice(0, -1))
        }
    }

    function isShiftOn() {
        if (reasonSelected) {
            return isEmpty(reason)
        }
        return false
    }

    return (
        <BookAppointmentConfirmationContainer>
            <BookAppointmentConfirmationInnerContainer>
                <TitleText>{t(Strings.Home.BookAppointment)}</TitleText>
                <SlotContentView>
                    <SlotView>
                        <SlotInfoView>
                            <SlotDateTextInConfirmation>{formattedDate}</SlotDateTextInConfirmation>
                            <SlotTimeTypeView>
                                <SlotTimeTextInConfirmation>{`${startTime} - ${endTime}`}</SlotTimeTextInConfirmation>
                                <SlotTypeTextInConfirmation>{`(${props.selectedSlot.slotTypeDescription})`}</SlotTypeTextInConfirmation>
                            </SlotTimeTypeView>
                            <SessionHolderNameTextInConfirmation>{`${props.selectedSlot.sessionHolderName}`}</SessionHolderNameTextInConfirmation>
                        </SlotInfoView>
                        <DividerInConfirmation/>
                        <SlotLocationTextInConfirmationContainer>
                            <SlotLocationTextInConfirmation>{`${props.selectedSlot.locationDisplayName}`}</SlotLocationTextInConfirmation>
                        </SlotLocationTextInConfirmationContainer>
                    </SlotView>
                    {!isShowReasonForAppointment ?
                        <ReasonContainer>
                            <ReasonHeaderText>
                                {`${t(Strings.BookAppointment.ReasonForAppointment)} (${isMandatory_ReasonForAppointment ? t(Strings.mandatory) : t(Strings.optional)})`}
                            </ReasonHeaderText>
                            <QuestionnaireTextView sx={{ height: showKeyboard ? 'auto' : '5vh', minHeight: '5vh' }} onClick={() => {
                                setReasonSelected(true)
                                setShowKeyboard(true)
                            }}>
                                {reason}
                            </QuestionnaireTextView>
                            <ReasonFooterText>{`${reason.length} / ${35}`}</ReasonFooterText>
                        </ReasonContainer>
                        : null
                    }
                </SlotContentView>
            </BookAppointmentConfirmationInnerContainer>
            {showKeyboard && reasonSelected ?
                <QuestionnaireKeyboardContainerView>
                    <TextKeyboardComponent
                        onKeyClick={onKeyClick}
                        onDeleteClick={onDeleteClick}
                        onCloseClick={() => setShowKeyboard(false)}
                        isShiftOn={isShiftOn()}
                    />
                </QuestionnaireKeyboardContainerView>
                : false
            }
            {!showKeyboard ?
                <FooterButtonView>
                    <BookAppointmentButton onClick={() => props.handleConfirmBookAppointmentAction(props.selectedSlot, reason)}>
                        {t(Strings.Home.BookAppointment)}
                    </BookAppointmentButton>
                </FooterButtonView>
                : null
            }
        </BookAppointmentConfirmationContainer>
    )
}


export default BookAppointmentConfirmation;