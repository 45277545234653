import { Divider } from "@mui/material";
import { ImageAltText, Strings } from "../../../../constants/StringConstant";
import { isEmpty } from "../../../../helpers/StringHelper";
import MessageBanner from "../../commonViews/MessageBanner";
import { useTranslation } from 'react-i18next';
import { DummyContainer } from "../../styles/StylesMatchingScreen";
import { HomeLogo } from "../../styles/StylesHome";

interface HomeScreenTopBannerProps {
    kioskTitle: string
    kioskLogo?: string
}

const HomeScreenTopBanner: React.FC<HomeScreenTopBannerProps> = (props: HomeScreenTopBannerProps) => {
    const { t } = useTranslation();
    return (
        <DummyContainer>
            {props.kioskLogo ?
                <DummyContainer>
                    <HomeLogo src={`data:image/jpeg;base64,${props.kioskLogo}`} alt={ImageAltText.KIOSKLogo} />
                    <Divider />
                </DummyContainer>
                :
                <MessageBanner message={isEmpty(props.kioskTitle) ? t(Strings.Home.Welcome) : t(Strings.Home.WelcomeTo)} title={props.kioskTitle} />
            }
        </DummyContainer>
    );
};

export default HomeScreenTopBanner