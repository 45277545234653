import { injectable } from "inversify";
import { APIClient } from "../APIClient";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { AppConfig } from "../../utils/AppConfig";
import { IAppointmentRepository } from "../interfaces/IAppointmentRepository";
import { SetAppointmentStatusParameters } from "../apiParameterModels/SetAppointmentStatusParameters";
import { AppointmentSessionsParameters } from "../apiParameterModels/AppointmentSessionsParameters";
import { SessionSlotsParameters } from "../apiParameterModels/SessionSlotsParameters";
import { BookAppointmentParams } from "../apiParameterModels/BookAppointmentParams";

@injectable()
export class AppointmentRepository implements IAppointmentRepository {
    setAppointmentStatus(params: SetAppointmentStatusParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            APIClient.postRequest(
                DD_APIEndPointName.SetAppointmentStatus_API_Endpoint,
                AppConfig.SetAppointmentStatus_API_Endpoint,
                params)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getAppointmentSessions(params: AppointmentSessionsParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            APIClient.postRequest(
                DD_APIEndPointName.GetAppointmentSessions_API_Endpoint,
                AppConfig.GetAppointmentSessions,
                params)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getSessionSlots(params: SessionSlotsParameters): Promise<string> {
        return new Promise((resolve, reject) => {
            APIClient.postRequest(
                DD_APIEndPointName.GetSlotsForSession_API_Endpoint,
                AppConfig.GetSessionSlots,
                params)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getAppointmentSlot(params: BookAppointmentParams): Promise<boolean> {
        return new Promise((resolve, reject) => {
            APIClient.postRequest(
                DD_APIEndPointName.BookAppointment_API_Endpoint,
                AppConfig.BookAppointment,
                params)
                .then(async response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}