/* GenderComponent.tsx */

import React from 'react';
import { Grid } from '@mui/material';
import { GenderComponentContainer, GenderGridButton, HyperlinkButton } from '../../styles/StylesMatchingScreen';
import { useTranslation } from 'react-i18next';
import { Strings } from '../../../../constants/StringConstant';

interface GridProps {
    gridAlignment: 'center' | 'left' | 'right',
    onGenderClick: (item: string) => void;
    declineText: string
}

const GenderComponent: React.FC<GridProps> = (props: GridProps) => {
    const { t } = useTranslation();
    const genders = [t(Strings.Matching.Male), t(Strings.Matching.Female), t(Strings.Matching.Other)];

    return (
        <GenderComponentContainer data-testid="GenderComponentContainer">
            <Grid data-testid="GenderComponentOuterGrid" container spacing={3} marginTop={2} justifyContent={props.gridAlignment}>
                {genders.map((gender, index) => {
                    return (
                        <Grid data-testid="GenderComponentInnerGrid" item key={`${index}.${gender}`}>
                            <GenderGridButton data-testid="GenderGridButton"
                                onClick={() => props.onGenderClick(genders[index])}
                                variant="contained"
                            >
                                {gender}
                            </GenderGridButton>
                        </Grid>
                    )
                })}
            </Grid>
            <HyperlinkButton data-testid="HyperlinkButton" onClick={() =>  props.onGenderClick('')}>
                {props.declineText}
            </HyperlinkButton>

        </GenderComponentContainer>
    );
};

export default GenderComponent;