import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeIcon from '@mui/icons-material/Home';
import LanguageIcon from '@mui/icons-material/Language';
import { Backdrop, Button, CircularProgress, DialogContent, styled, Typography } from "@mui/material";
import { ColorConstant } from "../../../constants/ColorConstant";
import { SizeConstant } from "../../../constants/SizeConstant";

/* TopBar */

export const LanguageButton = styled(Button)(({ theme }) => ({
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE, 
    textTransform: 'none', 
    fontWeight: 'bold',
    color: ColorConstant.WHITE,
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE
    },
    padding: `10px 0px`,
    minWidth: '130px',
    minHeight: 50,
    fontSize: 'large',
}));

export const LanguageDialogContent = styled(DialogContent)(({ theme }) => ({
    display: 'flex', 
    justifyContent: 'center', 
    borderRadius: 5, 
    border: `4px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`
}));

export const StyledAppBar = styled('div')<{ mobile: boolean }>(({ mobile }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'space-between',
    userSelect: 'none',
    color: ColorConstant.WHITE,
    height: mobile ? SizeConstant.TopBar.heightMobile : SizeConstant.TopBar.height
}));

export const NavBarHomeButton = styled(Button) ({
    backgroundColor: ColorConstant.BLUE_TUNA,
    aspectRatio: '1 / 1',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    margin: 20,
    alignSelf: 'center',
});

export const StyledHomeIcon = styled(HomeIcon)({
    color: 'white',
});

export const NavBarBackDiv = styled('div')<{ mobile: boolean }>(({ mobile }) => ({
    display: 'flex',
    height: mobile ? '70%' : '100%',
     gap: mobile ? 5 : 10
}));

export const NavBarBackButton = styled(Button)({
    backgroundColor: ColorConstant.BLUE_TUNA,
    margin: 20,
    alignSelf: 'center',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
});

export const StyledChevronLeftIconIcon = styled(ChevronLeftIcon)({
    color: 'black',
});

export const NavBarBackText = styled(Typography)({
    fontSize: 20,
    fontWeight: 'bold',
    color: ColorConstant.WHITE,
    marginRight: '15px',
    textTransform: 'none',
});

export const NavBarDateTimeSection = styled('div')<{ mobile: boolean, portrait: boolean }>(({ mobile, portrait }) => ({
    display: 'flex',
    flexDirection: mobile || portrait ? 'column' : 'row', 
    gap: mobile || portrait ? 0 : 10,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    position: 'absolute',
    
}));

export const NavBarDateText = styled('div') ({
    fontWeight: 'normal'
});

export const NavBarTimeText = styled('div')<{ mobile: boolean, portrait: boolean }>(({ mobile, portrait }) => ({
    fontWeight: 'bold',
    marginLeft: mobile || portrait ? 0 : 2
}));

export const NavBarLanguageButton = styled(Button)({
    backgroundColor: ColorConstant.BLUE_TUNA,
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    margin: 20,
    padding: 15,
    // ...(isSmallDimension ? { aspectRatio: '1 / 1' } : { width: 'auto' })
});

export const StyledLanguageDiv = styled('div')<{ mobile: boolean }>(({ mobile }) => ({
    display: 'flex',
    height: mobile ? '70%' : '100%',
}));

export const StyledLanguageEmptyDiv = styled('div')<{ mobile: boolean }>(({ mobile }) => ({
    display: 'flex',
    height: mobile ? '70%' : '100%'
}));

export const NavBarLanguageText = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    fontWeight: 'bold',
    color: ColorConstant.WHITE,
    margin: '0px 15px',
    textTransform: 'none',
    fontSize: isMobile ? 15 : 20
}));

export const StyledLanguageIcon = styled(LanguageIcon) ({
    color: ColorConstant.WHITE,
});

/* Banner */

export const HeaderQuestionHeaderText = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    color: ColorConstant.BLACK_SPACE,
    [theme.breakpoints.down('xs')]: {
        fontSize: 'larger', // Font size for xs
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 'x-large', // Font size for sm
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 'xx-large', // Font size for md and up
    },
}));

export const HeaderQuestionSubHeaderText = styled('div')(({ theme }) => ({
    fontWeight: 'normal',
    color: ColorConstant.DUGONG,
    [theme.breakpoints.down('xs')]: {
        fontSize: 'medium',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 'larger',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 'x-large',
    },
}));

export const MessageBannerView = styled('div')(({ theme }) => ({
    fontWeight: 'normal',
    color: ColorConstant.DARK_GREY,
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    justifyContent: 'center',
    backgroundColor: ColorConstant.BLIZZARD_BLUE,
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
        paddingTop: 5,
        paddingBottom: 5,
    },
    [theme.breakpoints.up('sm')]: {
        paddingTop: 7,
        paddingBottom: 7,
    },
    [theme.breakpoints.up('md')]: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    padding: '10px 0px',
}));

export const MessageBannerText = styled('div')(({ theme }) => ({
    fontWeight: 'normal',
    color: ColorConstant.BIG_SUR_BLUE_JADE,
    backgroundColor: ColorConstant.BLIZZARD_BLUE,
    [theme.breakpoints.down('xs')]: {
        fontSize: 'medium',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 'large',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 'x-large',
    },
}));

export const MessageBannerTitleText = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    marginLeft: 10,
    color: ColorConstant.BIG_SUR_BLUE_JADE,
    backgroundColor: ColorConstant.BLIZZARD_BLUE,
    [theme.breakpoints.down('xs')]: {
        fontSize: 'medium',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 'large',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 'x-large',
    },
}));

/* DateStripComponent */
export const DateStripComponentContainer = styled('div')({
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
    border: 'none',
    alignSelf: 'center',
    userSelect: 'none',
});

export const DateStripComponentInnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: ColorConstant.BELUGA
});

export const DateStripNextPreviousButton = styled(Button)<{ enablePreviousWeekSelection: boolean, isNextButton: boolean }>(({ enablePreviousWeekSelection, isNextButton }) => ({
    backgroundColor: enablePreviousWeekSelection ? ColorConstant.CERAMIC_BLUE_TURQUOISE : ColorConstant.GREY,
    marginLeft: isNextButton ? 10 : 0,
    marginRight: isNextButton ? 0 : 10,
    border: 'none',
    '&:hover': {
        backgroundColor: enablePreviousWeekSelection ? ColorConstant.EMERALD_STONE : ColorConstant.DUGONG,
    },
}));

export const StyledChevronBackIcon = styled(ChevronLeftRoundedIcon)<{ mobile: boolean }>(({ mobile }) => ({
    fontSize: mobile ? 35 : 50,
    color: 'white',
}));

export const StyledChevronForwardIcon = styled(ChevronRightRoundedIcon)<{ mobile: boolean }>(({ mobile }) => ({
    fontSize: mobile ? 35 : 50,
    color: 'white',
}));

export const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
}));

export const StyledCircularProgress = styled(CircularProgress)({
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
});

/* SomethingWentWrongScreen */
export const SomethingWentWrongScreenContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100%',
    alignItems: 'center',
    userSelect: 'none',
});

export const SomethingWentWrongScreenInfoContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
});

export const SorryLooksLikeSomethingWentWrongText = styled('div')({
    fontSize: 'xx-large',
    fontWeight: 'bold',
    color: ColorConstant.IRON,
    padding: '5px',
});

export const SorryUnableToProcessYourRequestText = styled('div')({
    fontSize: 'x-large',
    color: ColorConstant.IRON,
    padding: '5px',
});

export const PleaseReportToReceptionText = styled('div')({
    fontSize: 'medium',
    color: ColorConstant.IRON,
    padding: '5px',
});

export const SomethingWentWrongScreenCloseButton = styled(Button) ({
    width: '50%',
    height: '60px',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    color: ColorConstant.WHITE,
    border: 'none',
    borderRadius: '5px',
    marginBottom: '50px',
    fontSize: 'x-large',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    textTransform: 'none',
});

/* TextKeyboardComponent */
export const TextKeyboardComponentContainer = styled('div')({
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
});

export const TextKeyboardContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    flex: 1,
    [theme.breakpoints.between('xs', 'md')]: {
        maxWidth: '60%',
    },
    [theme.breakpoints.only('md')]: {
        maxWidth: '70%',
    },
    [theme.breakpoints.only('lg')]: {
        maxWidth: '50%',
    },
    [theme.breakpoints.only('xl')]: {
        maxWidth: '50%',
    },
}));

export const KeyButton = styled(Button)({
    height: 45,
    padding: `5px 10px`,
    margin: '5px',
    fontSize: 'larger',
    gridColumn: 'auto',
    backgroundColor: ColorConstant.WHITE,
    color: ColorConstant.IRON,
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
        color: ColorConstant.WHITE,
    },
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    textTransform: 'none',
});

export const EmptyKey = styled('div')({
    
});

export const DeleteKeyTextButton = styled(Button)({
    height: 45,
    padding: `5px 10px`,
    margin: '5px',
    fontSize: 'larger',
    gridColumn: 'auto',
    backgroundColor: ColorConstant.WHITE,
    color: ColorConstant.IRON,
    '&:hover': {
        backgroundColor: ColorConstant.RED_POWER,
        color: ColorConstant.WHITE,
    },
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
});

export const DeleteImage = styled('img')({
    width: '25%',
    aspectRatio: '1',
});

export const NumberPadContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    maxWidth: '20%',
});
