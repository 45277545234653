import { Button, styled, Typography } from "@mui/material";
import { ColorConstant } from "../../../constants/ColorConstant";
import { SizeConstant } from "../../../constants/SizeConstant";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const DummyContainer = styled('div')({});

/* MatchingContainer, Components */
export const MatchingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  height: `calc(100vh - 155px)`,
  width: '100vw',
});

export const HeaderQuestionContainer = styled('div')({
  marginTop: 40
});

export const MatchingInnerContainer = styled('div')({
  display: 'flex',
  padding: '0% 10%',
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  overflow: 'hidden',
});

export const CharacterGridComponentContainer = styled('div')({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  paddingBottom: 50
});

export const SurnameGridButton = styled(Button)({
  height: `${SizeConstant.Matching.Surname.gridHeight}px`,
  width: `${SizeConstant.Matching.Surname.gridWidth}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'x-large',
  fontWeight: '500',
  color: 'white',
  backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
  },
  textTransform: 'none',
});

export const PostCodeComponentContainer = styled('div')({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  paddingBottom: 50
})

export const PostCodeGridContainer = styled('div')({
  display: 'flex',
  overflow: 'auto',
});

export const PostalGridButton = styled(Button)({
  height: `${SizeConstant.Matching.Year.gridHeight}px`,
  width: `${SizeConstant.Matching.Postal.gridWidth}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'x-large',
  fontWeight: '500',
  color: 'white',
  backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
  },
  textTransform: 'none',
});

export const PostalFooterView = styled('div')({
  justifyContent: 'center',
  display: 'flex'
});

export const FooterButton = styled(Button)({
  marginTop: 50,
  border: `3px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`,
  display: 'flex',
  fontSize: 'x-large',
  fontWeight: '600',
  color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  textAlign: 'center',
  padding: '10px 20px',
  borderRadius: 5,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
    color: ColorConstant.WHITE,
  },
  textTransform: 'none',
});

export const DateComponentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  userSelect: 'none',
  height: '100%',
  marginTop: '5%'
});

export const ErrorValidationContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'center',
  marginBottom: '10px'
});

export const ErrorValidationText = styled('div')({
  fontSize: 'x-large',
  color: ColorConstant.RED_POWER,
});

export const DateComponentView = styled('div')({
  alignSelf: 'center'
});

export const DateSelectorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: 20,
  userSelect: 'none',
});

export const DateSelectorComponentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 80,
  justifyContent: 'center',
  alignItems: 'center'
});

export const DateSelectorComponentText = styled('div')({
  fontSize: 'xx-large',
  fontWeight: 'bold',
});

export const DateSelectorSlashText = styled('div')({
  fontSize: 'xx-large',
  fontWeight: 'bold',
  color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  height: '100%',
});

export const DateSelectorDashText = styled('div')({
  height: 3,
  width: '80%',
  fontWeight: 'bold',
  borderRadius: 5,
  marginTop: 5,
});

/* KeyboardComponent */
export const KeyboardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  userSelect: 'none'
});

export const KeyboardGridView = styled('div')({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  gap: 15,
});

export const KeyboardGridButton = styled(Button)({
  height: '7vh',
  aspectRatio: '1.5',
  fontSize: 'x-large',
  cursor: 'pointer',
  border: 'none',
  borderRadius: 5,
  backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  color: ColorConstant.WHITE,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
  },
  textTransform: 'none',
});

export const KeyboardGridEmptyView = styled('div')({
  height: '7vh',
  aspectRatio: '1.5',
  backgroundColor: ColorConstant.WHITE,
  color: ColorConstant.WHITE
});

export const KeyboardDeleteButton = styled(Button)({
  height: '7vh',
  aspectRatio: '1.5',
  cursor: 'pointer',
  border: 'none',
  borderRadius: 5,
  backgroundColor: ColorConstant.WHITE,
  position: 'relative',
  textTransform: 'none',
});

export const KeyboardDeleteIcon = styled('img')({
  width: '100%',
});

export const KeyboardNextButton = styled(Button)({
  marginTop: 20,
  height: '7vh',
  fontSize: 20,
  fontWeight: 'bold',
  width: '100%',
  border: 'none',
  borderRadius: 5,
  textTransform: 'none',
});

export const DayGridComponentContainer = styled('div')({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  paddingBottom: 50
});

export const DayGridButton = styled(Button)({
  height: `${SizeConstant.Matching.Day.gridHeight}px`,
  width: `${SizeConstant.Matching.Day.gridWidth}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'x-large',
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
  },
  textTransform: 'none',
});

export const DayTextTypography = styled(Typography)({
  display: 'inline-block',
  '& .bold-text': {
    fontWeight: 'bold',
    fontSize: 'x-large'
  },
  '& .small-text': {
    fontSize: 'medium',
    verticalAlign: 'top',
    textTransform: 'none',
  },
});

export const MonthGridComponentContainer = styled('div')({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  paddingBottom: 50
});

export const MonthGridButton = styled(Button)({
  height: `${SizeConstant.Matching.Month.gridHeight}px`,
  width: `${SizeConstant.Matching.Month.gridWidth}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'larger',
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
  },
  textTransform: 'none',
});

export const YearGridComponentContainer = styled('div')({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  height: '100%',
});

export const YearGridContainer = styled('div')({
  flex: 'auto',
  height: '80%',
  overflow: 'auto',
});

export const YearGridButton = styled(Button)({
  height: `${SizeConstant.Matching.Year.gridHeight}px`,
  width: `${SizeConstant.Matching.Year.gridWidth}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'x-large',
  fontWeight: '500',
  color: 'white',
  backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
  },
  textTransform: 'none',
});

export const YearComponentFooterView = styled('div')({
  justifyContent: 'center',
  display: 'flex',
  marginBottom: '2%',
  height: 120
});

export const NoneOfTheAboveButton = styled(Button)({
  marginTop: 50,
  border: `3px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`,
  display: 'flex',
  fontSize: 'x-large',
  fontWeight: '600',
  color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  textAlign: 'center',
  padding: '10px 20px',
  borderRadius: 5,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
    color: ColorConstant.WHITE,
  },
  textTransform: 'none',
});

export const GenderComponentContainer = styled('div')({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  paddingBottom: 50
})

export const GenderGridButton = styled(Button)({
  height: `${SizeConstant.Matching.Gender.gridHeight}px`,
  width: `${SizeConstant.Matching.Gender.gridWidth}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'x-large',
  fontWeight: '500',
  color: 'white',
  backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
  },
  textTransform: 'none',
});

export const HyperlinkButton = styled(Button)({
  textTransform: 'none',
  textDecoration: 'underline',
  color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  padding: 20,
  alignSelf: 'center',
  fontSize: 'larger',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  marginTop: 50,
});

/* NoMatchingScreen*/
export const NoMatchingScreenContainer = styled('div')({
  display: 'flex',
  flex: 1,
  backgroundColor: 'white',
});

export const NoMatchingScreenInnerContainer = styled('div')({
  margin: '0% 10%',
  alignSelf: 'center',
});

export const NoMatchingScreenHeaderView = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
});

export const NoMatchingScreenTitleText = styled('div')({
  display: 'flex',
  flex: 1,
  textAlign: 'left',
  color: ColorConstant.IRON,
  fontSize: '2.3em',
  fontWeight: 'bold',
  padding: 20,
});

export const StyledCancelOutlinedIcon = styled(CancelOutlinedIcon)({
  color: ColorConstant.RED_POWER,
  fontSize: 50,
});

export const NoMatchingScreenDivider = styled('div')({
  height: 2,
  backgroundColor: ColorConstant.WHITE_EDGAR,
  marginBottom: 20,
});

export const NoMatchingScreenInfoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const NoMatchingScreenInfoInnerContainer = styled('div')({
  width: '50%',
});

export const NoMatchingInfoText = styled('div')({
  color: ColorConstant.GREY,
  textAlign: 'left',
  marginBottom: 20,
  fontSize: '2.2em',
  lineHeight: 1.5,
});

export const NoMatchingScreenFooterButtonContainer = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'stretch',
  padding: '10px 0',
  minHeight: '60px'
});

export const NoMatchingScreenFooterButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.5em',
  cursor: 'pointer',
  width: '45%',
  border: 'none',
  borderRadius: 5,
  padding: '10px 20px',
  backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
  color: ColorConstant.WHITE,
  '&:hover': {
    backgroundColor: ColorConstant.EMERALD_STONE,
  },
  textTransform: 'none',
});

export const NoMatchingScreenValuesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 10,
});

export const NoMatchingScreenValuesTextView = styled('div')({
  display: 'flex',
  backgroundColor: ColorConstant.Blue_DarkNut,
  padding: '5px 20px',
});

export const NoMatchingScreenValuesTitleText = styled('span')({
  fontSize: '1.2em',
  fontWeight: 'bold',
  marginRight: 5,
  lineHeight: 1.5,
  color: ColorConstant.GREY,
});

export const NoMatchingScreenValuesValueText = styled('span')({
  flex: 1,
  fontSize: '1.2em',
  textAlign: 'left',
  lineHeight: 1.5,
  marginRight: 5,
  fontWeight: 'bold',
  color: ColorConstant.CERAMIC_BLUE_TURQUOISE
});