import { getTokenBaseURL } from "./ApiRequestUtil";


export const B2CConstants = {
    grantType: 'client_credentials',
    scope: `${getTokenBaseURL()}x/.default`,
    Dev: {
        clientId: '',
        clientSecret: '',
    },
    Live: {
        clientId: '',
        clientSecret: '',
    }
}