import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CheckInContainer from '../view/web/screens/checkIn/CheckInContainer';
import HomeScreen from '../view/web/screens/home/HomeScreen';
import { RouterName } from './RouterName';
import BookAppointmentContainer from '../view/web/screens/bookAppointment/BookAppointmentContainer';

const RouterComponent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route
                    path={RouterName.Home}
                    element={<HomeScreen />}
                />
                <Route
                    path={RouterName.CheckIn}
                    element={<CheckInContainer />}
                />
                <Route
                    path={RouterName.BookAppointment}
                    element={<BookAppointmentContainer />}
                />
            </Routes>
        </Router>
    );
};

export default RouterComponent;
