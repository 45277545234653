/* BookAppointmentSelection.tsx */

import React from 'react'
import { Strings } from '../../../../../constants/StringConstant'
import { SlotUIEntity } from '../../../../../uiEntities/SlotUIEntity'
import { BookAppointmentViewModel } from '../../../../../viewModels/BookAppointmentViewModel'
import HeaderQuestion from '../../../commonViews/HeaderQuestion'
import { AppointmentTypesContainer, BookAppointmentSelectionContainer, BookAppointmentSelectionInnerContainer, HeaderQuestionContainer, RightSideContainer, SlotTitleText } from '../../../styles/StylesBookAppointment'
import AppointmentSelectionView from './AppointmentSelectionView'
import NextAvailableAppointmentView from './NextAvailableAppointmentView'
import { useTranslation } from 'react-i18next'

interface BookAppointmentSelectionProps {
    handleBookThisAppointmentAction: (slot: SlotUIEntity) => void
    handleChooseSpecificDateAction: (date: Date) => void
    nextAvailableSlot: SlotUIEntity | undefined
}

const BookAppointmentSelection: React.FC<BookAppointmentSelectionProps> = (props: BookAppointmentSelectionProps) => {
    const { t } = useTranslation();
    const appointments = BookAppointmentViewModel().getAppointments()
    return (
        <BookAppointmentSelectionContainer>
            <BookAppointmentSelectionInnerContainer>
                <AppointmentTypesContainer>
                    <HeaderQuestionContainer>
                        <HeaderQuestion headerText={t(Strings.BookAppointment.WhenDoYouWantTheAppointment)} subHeaderText={''} />
                    </HeaderQuestionContainer>
                    <AppointmentSelectionView
                        appointments={appointments}
                        handleChooseSpecificDateAction={props.handleChooseSpecificDateAction}
                    />
                </AppointmentTypesContainer>
                {props.nextAvailableSlot ?
                    <RightSideContainer>
                        <SlotTitleText>{t(Strings.BookAppointment.NextAvailableAppointment)}</SlotTitleText>
                        <NextAvailableAppointmentView nextAvailableSlot={props.nextAvailableSlot} handleBookThisAppointmentAction={props.handleBookThisAppointmentAction} />
                    </RightSideContainer>
                    : null
                }
            </BookAppointmentSelectionInnerContainer>
        </BookAppointmentSelectionContainer>
    )
}

export default BookAppointmentSelection