import { injectable } from "inversify";
import { AppConfig } from "../../utils/AppConfig";
import { B2CConstants } from "../../utils/EnvironmentConfig";
import { APIClient } from "../APIClient";
import { TokenParameters } from "../apiParameterModels/TokenParameters";
import { ITokenRepository } from "../interfaces/ITokenRepository";

@injectable()
export class TokenRepository implements ITokenRepository {
    getToken(): Promise<string> {
        const tokenRequestData: TokenParameters = {
            grant_type: B2CConstants.grantType,
            scope: B2CConstants.scope,
        }
        return new Promise((resolve, reject) => {
            APIClient.postTokenRequest(AppConfig.Token_Endpoint, tokenRequestData)
            .then(async response => {
                resolve(response);
            })
            .catch((error: Error) => {
                reject(error)
            })
        })
    }
}